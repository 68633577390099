import '@svgdotjs/svg.draggable.js';
import { SVG } from '@svgdotjs/svg.js';
import '@svgdotjs/svg.panzoom.js';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { isReservedWord } from '../common';
import ShowIf from '../show-if';
import { followPath } from './animate';
import Grid from './grid';
import images from './images/images';
import './styles.css';
import './svg.connectable-new';
import svgToPng from './svgtopng';

const DEFAULTS = {
    elemXPos: 0,
    elemYPos: 0,
    elemWidth: 150,
    elemHeight: 150,
    fill: 'lightgray',
    txtColor: 'black',
    fontSize: {
        rect: 14,
        image: 12,
        lang: 9,
        path: 12
    },
    lang: {
        size: 30,
        rectSize: 34
    },
    rect: {
        radius: 15
    }
}

const CONSTANTS = {
    SVG_WIDTH: 1100,
    SVG_HEIGHT: 800,
    MIN_WIDTH: 500,
    MIN_HEIGHT: 500,
    RESIZER_SIZE: 10,
    PAN_BTN_SIZE: 30,
    PAN_FONT_SIZE: 20,
    LEGEND_PADDING: 20
}

const SVGDiagram = forwardRef(({ data, layout, appearance, components, onAnimationComplete, onZoom, onLayoutChange, draggable }, ref) => {
    const svgRef = useRef()
    const thatRef = useRef()
    const [zoomData, setZoomData] = useState(null)
    const [stepCard, setStepCard] = useState({ style: null, content: {} })
    const [stepCardBtn, setStepCardBtn] = useState({ prev: { disabled: true }, next: { disabled: true } })

    useEffect(() => {
        thatRef.current = {}
        init()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (zoomData) {
            const { component, prev } = zoomData
            onZoom(component, prev)
        }
        // eslint-disable-next-line
    }, [zoomData])

    useImperativeHandle(ref, () => ({
        playSteps(speed, mode) {
            if (thatRef.current.playingSteps) return
            thatRef.current.playingSteps = true
            thatRef.current.speed = speed
            thatRef.current.mode = mode
            const { x: contX, y: contY } = svgRef.current.getBoundingClientRect()
            const { x: svgX, y: svgY } = svgRef.current.querySelector('svg').getBoundingClientRect()

            thatRef.current.shiftX = svgX - contX
            thatRef.current.shiftY = svgY - contY

            thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, mode, status: 'playing' }

            // Just play the animation
            if (thatRef.current.stepsAnimation && thatRef.current.stepsAnimation.status === 'paused') {
                thatRef.current.stepsAnimation.animation.play(thatRef.current.speed)
            }

            // Start from scratch
            else {
                const circle = thatRef.current.draw.circle(10)
                const sortedConnections = thatRef.current.connectionsData.sort((a, b) => a.stepIdx > b.stepIdx)

                thatRef.current.stepsAnimation = { circle, sortedConnections }
                thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, stepsCount: sortedConnections.length }

                animateStep(1)
            }

            updateStepBtns()
        },

        pauseSteps() {
            if (thatRef.current.stepsAnimation) {
                thatRef.current.stepsAnimation.status = 'paused'
                thatRef.current.stepsAnimation.animation.pause()
                thatRef.current.playingSteps = false
                thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, status: 'paused' }
            }
        },

        stopSteps,

        buildLayoutAndImage() {
            const layout = prepareLayoutData()
            const { width, height } = thatRef.current.containerSize

            thatRef.current.resizer.hide()

            // TODO: stop playing steps before generating the image
            stopSteps()
            return svgToPng(thatRef.current.svg.svg(), width, height)
                .then(imageBlob => {
                    thatRef.current.resizer.show()

                    return { layout, imageBlob }
                })
                .catch(console.error)
        },

        zoom(val) {
            const { height: svgHeight, width: svgWidth } = thatRef.current.originalSize
            const { height: contHeight, width: contWidth } = svgRef.current.getBoundingClientRect()

            switch (val) {
                case 'in':
                    thatRef.current.scale = Math.min(2, thatRef.current.scale + 0.25)
                    break
                case 'out':
                    thatRef.current.scale = Math.max(.25, thatRef.current.scale - 0.25)
                    break
                case 'fit':
                    // 80 is a magic number 😃
                    const wScale = (contWidth - 80) / svgWidth
                    const hScale = (contHeight - 80) / svgHeight
                    thatRef.current.scale = Math.min(wScale, hScale)
                    break
                case 100:
                    thatRef.current.scale = 1
                    break
                default:
            }

            scaleSVG()

            return thatRef.current.scale
        },
    }))

    useEffect(() => {
        update(data, layout, appearance, components)
        // eslint-disable-next-line
    }, [data, layout, appearance, components])

    function updateStepBtns() {
        if (thatRef.current && thatRef.current.animationMetadata) {
            setStepCardBtn({
                prev: {
                    disabled: thatRef.current.animationMetadata.status === 'playing' ||
                        thatRef.current.animationMetadata.step < 2
                },
                next: {
                    disabled: thatRef.current.animationMetadata.status === 'playing' ||
                        thatRef.current.animationMetadata.step >= thatRef.current.animationMetadata.stepsCount
                }
            })
        }
    }

    function animateStep(step) {
        thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, step, status: 'playing' }
        updateStepBtns()

        const { circle, sortedConnections } = thatRef.current.stepsAnimation
        const { id, data } = sortedConnections[step - 1]
        const { connector, textElem } = thatRef.current.connections[id]

        if (!textElem.attr('data-flipped') && shouldFlipText(connector)) {
            textElem.flip('both').attr('data-flipped', true)
        }

        thatRef.current.stepsAnimation.animation = followPath(circle, connector, (x, y) => {
            setStepCard({
                style: {
                    top: y * thatRef.current.scale + thatRef.current.shiftY,
                    left: x * thatRef.current.scale + thatRef.current.shiftX,
                    display: 'block'
                },
                content: { ...data, title: `[${step}/${sortedConnections.length}] ` + data.title }
            })
        }, () => {
            if (step < sortedConnections.length) {
                if (thatRef.current.mode === 'Continuous') {
                    animateStep(step + 1)
                    thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, step: step + 1 }
                } else {
                    thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, status: 'paused' }
                }
            } else {
                if (thatRef.current.mode === 'Continuous') {
                    handleAnimationComplete()
                } else {
                    thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, status: 'paused' }
                }
            }

            updateStepBtns()
        }, thatRef.current.speed)
    }

    function handleAnimationComplete() {
        thatRef.current.stepsAnimation.circle.remove()
        thatRef.current.playingSteps = false
        thatRef.current.stepsAnimation.status = 'completed'
        thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, status: 'completed' }

        if (onAnimationComplete) {
            onAnimationComplete()
            setStepCard({
                style: { display: 'none' },
                content: {}
            })
        }
    }

    // TODO: fix me, the step labels keep changing after stopping 
    function stopSteps() {
        if (thatRef.current.stepsAnimation) {
            thatRef.current.stepsAnimation.status = 'stopped'
            thatRef.current.stepsAnimation.animation.stop()
            thatRef.current.stepsAnimation.circle.remove()
            thatRef.current.playingSteps = false
            console.log(thatRef.current.stepsAnimation)
            setStepCard({
                style: { display: 'none' },
                content: {}
            })
            thatRef.current.animationMetadata = { ...thatRef.current.animationMetadata, step: 0, status: 'stopped' }
            updateStepBtns()
        }
    }

    function playNextStep() {
        animateStep(thatRef.current.animationMetadata.step + 1)
    }

    function playPrevStep() {
        animateStep(thatRef.current.animationMetadata.step - 1)
    }

    function hideStepCard() {
        handleAnimationComplete()
    }

    function scaleSVG() {
        const svg = svgRef.current.querySelector('svg').querySelector('g')

        const { height: svgHeight, width: svgWidth } = thatRef.current.originalSize
        const newWidth = svgWidth * thatRef.current.scale
        const newHeight = svgHeight * thatRef.current.scale

        thatRef.current.containerSize = { width: newWidth, height: newHeight }
        thatRef.current.svg.size(newWidth, newHeight)

        svg.style.transform = `scale(${thatRef.current.scale})`

        if (thatRef.current.resizer) {
            if (thatRef.current.scale === 1) {
                thatRef.current.resizer.show()
            } else {
                thatRef.current.resizer.hide()
            }
        }

        centerSVGVertically()
    }

    function init() {
        if (svgRef.current.querySelector('svg')) {
            svgRef.current.querySelector('svg').remove()
        }

        thatRef.current.svg = SVG().addTo(svgRef.current)
            .size(CONSTANTS.SVG_WIDTH, CONSTANTS.SVG_HEIGHT)

        thatRef.current.draw = thatRef.current.svg.group()

        thatRef.current.data = {}
        thatRef.current.connections = {}
        thatRef.current.elements = []
        thatRef.current.elementsToConnect = []
        thatRef.current.connectionsData = []
        thatRef.current.elementsData = []
        thatRef.current.layout = {}
        thatRef.current.appearance = {}
        thatRef.current.grid = null
        thatRef.current.playingSteps = false
        thatRef.current.scale = 1
        thatRef.current.animationMetadata = {}

        renderLegend()
    }

    function update(data, layout, appearance, components) {
        try {
            // clear diagram memory
            thatRef.current.elements = []
            thatRef.current.elementsToConnect = []
            thatRef.current.connectionsData = []
            thatRef.current.elementsData = []

            thatRef.current.scale = 1
            thatRef.current.layout = layout
            thatRef.current.data = data
            thatRef.current.appearance = appearance
            thatRef.current.components = components

            render()
        } catch (e) {
            console.error('Invalid diagram data or layout:', e)
        }
    }

    function render() {
        thatRef.current.draw.clear()
        thatRef.current.draw.forget()
        thatRef.current.draw.size(0, 0)

        const services = getServicesFromData()

        thatRef.current.grid = new Grid({
            width: DEFAULTS.elemWidth,
            height: DEFAULTS.elemHeight,
            padding: 100,
            count: services.length,
        })

        thatRef.current.containerSize = getContainerSize()
        thatRef.current.originalSize = getContainerSize()
        thatRef.current.svg.size(thatRef.current.containerSize.width, thatRef.current.containerSize.height)

        scaleSVG()

        thatRef.current.elementsData = services.map(elem => buildElementData({ name: elem }))

        if (thatRef.current.data.steps) {
            thatRef.current.connectionsData = thatRef.current.data.steps.map((step, idx) => buildConnectionData({
                idx,
                data: buildConnectionPlayTxt(step),
                sourceId: thatRef.current.elementsData.find(elem => elem.name === step.from).id,
                targetId: thatRef.current.elementsData.find(elem => elem.name === step.to).id,
                text: step['action'],
            }))
        }

        thatRef.current.elementsData.forEach(_ => createElementFromData(_))
        thatRef.current.connectionsData.forEach(_ => createConnectionFromData(_))

        // renderPanControls()
        renderLegend()
        centerSVGVertically()
        if (draggable) renderResizer()
    }

    function renderLegend() {
        const legend = thatRef.current.draw.nested()

        if (thatRef.current.appearance && thatRef.current.appearance.groups) {
            thatRef.current.appearance.groups.forEach((group, idx) => {
                const { name, 'bg-color': bgColor } = group
                const _nested = legend.nested()

                _nested.x(0).y(30 * idx)
                _nested.rect(15, 15).fill(bgColor).x(0).y(0)
                _nested.text(name).x(22).y(-1)
            })
        }

        const { width, height } = legend.bbox()

        legend.x(thatRef.current.svg.width() - width - CONSTANTS.LEGEND_PADDING)
        legend.y(thatRef.current.svg.height() - height - CONSTANTS.LEGEND_PADDING)

        thatRef.current.legend = legend
    }

    function renderResizer() {
        thatRef.current.resizer = thatRef.current.draw.rect(CONSTANTS.RESIZER_SIZE, CONSTANTS.RESIZER_SIZE)
            .fill('black')
            .addClass('resizer')
            .x(thatRef.current.svg.width() - CONSTANTS.RESIZER_SIZE)
            .y(thatRef.current.svg.height() - CONSTANTS.RESIZER_SIZE)
            .on('mousedown', evt => resizeSVG(evt))
    }

    function resizeSVG(evt) {
        const startX = evt.clientX
        const startY = evt.clientY
        const startWidth = thatRef.current.svg.width()
        const startHeight = thatRef.current.svg.height()

        const { height: legendHeight, width: legendWidth } = thatRef.current.legend.bbox()

        const doDrag = (evt) => {
            const width = Math.max(CONSTANTS.MIN_WIDTH, startWidth + evt.clientX - startX)
            const height = Math.max(CONSTANTS.MIN_HEIGHT, startHeight + evt.clientY - startY)

            thatRef.current.svg.width(width)
            thatRef.current.svg.height(height)
            thatRef.current.resizer.x(width - CONSTANTS.RESIZER_SIZE)
            thatRef.current.resizer.y(height - CONSTANTS.RESIZER_SIZE)
            thatRef.current.legend.x(width - legendWidth - CONSTANTS.LEGEND_PADDING)
            thatRef.current.legend.y(height - legendHeight - CONSTANTS.LEGEND_PADDING)
            thatRef.current.containerSize = { width, height }
        }

        const stopDrag = () => {
            document.removeEventListener('mousemove', doDrag)
            document.removeEventListener('mouseup', stopDrag)

            centerSVGVertically()
            onLayoutChange(prepareLayoutData())
        }

        document.addEventListener('mousemove', doDrag)
        document.addEventListener('mouseup', stopDrag)
    }

    function centerSVGVertically() {
        const contHeight = svgRef.current.getBoundingClientRect().height

        if (thatRef.current.containerSize.height < contHeight - 80) { // padding: 40
            thatRef.current.svg.addClass('short')
        } else {
            thatRef.current.svg.removeClass('short')
        }
    }

    function getContainerSize() {
        const { width, height } = thatRef.current.grid.calcGridSize()

        return {
            width: thatRef.current.layout.containerWidth || width,
            height: thatRef.current.layout.containerHeight || height
        }
    }

    function getServicesFromData() {
        if (thatRef.current.data.steps) {
            const servicesDict = thatRef.current.data.steps.reduce((acc, step) => {
                acc[step.from] = true
                acc[step.to] = true
                return acc
            }, {})

            return Object.keys(servicesDict)
        }

        return []
    }

    function buildConnectionPlayTxt(step) {
        const reqData = step['req-data']
        const resData = step['res-data']
        const action = step.action
        const description = step.description

        return {
            title: action,
            description,
            dataList: [
                `Request: ${reqData ? reqData.join(', ') : 'not specified'}`,
                `Response: ${resData ? resData.join(', ') : 'not specified'}`,
            ]
        }
    }

    function buildElementData({ name = '' }) {
        const type = isReservedWord(name) ? name.toLowerCase() : 'rect'

        const { c, r } = thatRef.current.grid.addElementToCell(name)
        const { cx, cy } = thatRef.current.grid.centerPosition(c, r)

        const component = (thatRef.current.components || []).find(_ => _.name === name)

        return {
            bbox: thatRef.current.layout[name] || {
                x: DEFAULTS.elemXPos,
                y: DEFAULTS.elemYPos,
                cx,
                cy,
                width: DEFAULTS.elemWidth,
                height: DEFAULTS.elemHeight
            },
            id: uuidv4(),
            type,
            name,
            component
        }
    }

    function buildConnectionData({ idx, data, sourceId, targetId, text }) {
        return {
            id: uuidv4(),
            idx,
            data,
            sourceId,
            targetId,
            text,
            config: {
                marker: 'default',
                sourceAttach: 'perifery',
                targetAttach: 'perifery',
            }
        }
    }

    function prepareLayoutData() {
        const elementsLayout = thatRef.current.elements.reduce((acc, elem) => {
            // TODO: find more reliable method to identify elements
            acc[elem.name] = elem.bbox
            return acc
        }, {})

        return {
            ...elementsLayout,
            containerWidth: thatRef.current.svg.width(),
            containerHeight: thatRef.current.svg.height(),
        }
    }

    function calculateTextPos(type, bbox) {
        if (type === 'rect') return [bbox.cx, bbox.cy]

        // For logos, +10 to keep a bottom margin
        return [bbox.cx, bbox.cy + bbox.height / 2 + 10]
    }

    function getElemAppearance(label) {
        const appearance = { fill: DEFAULTS.fill, txtColor: DEFAULTS.txtColor }

        if (thatRef.current.appearance && thatRef.current.appearance.groups) {
            thatRef.current.appearance.groups.forEach(group => {
                if (group.components) {
                    if (group.components.includes(label)) {
                        appearance.fill = group['bg-color'] || DEFAULTS.fill
                        appearance.txtColor = group['txt-color'] || DEFAULTS.txtColor
                    }
                }
            })
        }

        return appearance
    }

    function adjustTextToFitCont(text, font, contWidth) {
        const words = text.split(' ')

        const _textElem = thatRef.current.draw.text().font(font)

        let line = ''
        const lines = []
        for (let i = 0; i < words.length; i++) {
            const word = words[i]
            const _line = (line ? line + ' ' : line) + word

            _textElem.text(_line)

            if (_textElem.length() >= contWidth - 10) {
                lines.push(line)
                line = word
            } else {
                line = _line
            }

            // We reached the end
            if (i === words.length - 1) {
                lines.push(line)
            }
        }

        _textElem.remove()
        return lines.join('\n')
    }

    function dragEnabled() {
        return thatRef.current.scale === 1
    }

    function createElementFromData(elementData) {
        const { type, name, id, bbox, component } = elementData

        const element = {
            id,
            type,
            name,
            label: null,
            elem: null,
            bbox: bbox
        }

        let labelFontSize = DEFAULTS.fontSize.rect
        let labelCenter = calculateTextPos(type, bbox)

        const appearance = getElemAppearance(name)

        if (type === 'rect') {
            labelFontSize = DEFAULTS.fontSize.rect
            element.elem = thatRef.current.draw.rect(bbox.width, bbox.height).fill(appearance.fill).radius(DEFAULTS.rect.radius)
        } else {
            element.elem = thatRef.current.draw.image(images[type])
            element.elem.size(bbox.width, bbox.height)
            labelFontSize = DEFAULTS.fontSize.image
        }

        element.elem.center(bbox.cx, bbox.cy)

        if (draggable) {
            element.elem.draggable()
            element.elem.addClass('cursor-grab')
        }

        element.label = thatRef.current.draw.text().fill(appearance.txtColor)
        element.label.font('size', labelFontSize + 'px')
        element.label.attr('text-anchor', 'middle')

        const fittedLabel = adjustTextToFitCont(name, element.label.font(), bbox.width)
        element.label.text(fittedLabel)
        element.label.center(labelCenter[0], labelCenter[1])

        if (component) {
            if (onZoom) {
                element.zoomBtn = thatRef.current.draw.image(images.inside).size(30, 30).addClass('cursor-pointer')

                element.zoomBtn.click(() => {
                    setZoomData({
                        component, prev: {
                            layout: thatRef.current.layout,
                            data: thatRef.current.data,
                            appearance: thatRef.current.appearance
                        }
                    })
                })

                placeZoomBtn(bbox)
            }

            const lang = component.data.language.toLowerCase()
            if (component.data.language && isReservedWord(lang)) {
                element.langRect = thatRef.current.draw.rect(DEFAULTS.lang.rectSize, DEFAULTS.lang.rectSize).fill('#fff')
                element.langImg = thatRef.current.draw.image(images[lang]).size(DEFAULTS.lang.size, DEFAULTS.lang.size)
                element.langLabel = thatRef.current.draw.text(component.data.language).font('size', DEFAULTS.fontSize.lang + 'px')
                placeLangElems(bbox)
            }
        }

        function placeZoomBtn(bbox) {
            const cx = bbox.cx + bbox.width / 2 - 25
            const cy = bbox.cy + bbox.height / 2 - 25

            element.zoomBtn.center(cx, cy)
        }

        function placeLangElems(bbox) {
            const cx = bbox.cx + (bbox.width / 2)
            const cy = bbox.cy - (bbox.height / 2)

            element.langImg.center(cx, cy)
            element.langLabel.center(cx, cy - DEFAULTS.lang.size / 2 - 8)
            element.langRect.center(cx, cy)
        }

        element.elem.on('beforedrag', e => {
            if (!dragEnabled()) {
                e.preventDefault()
            }
        })

        element.elem.on('dragmove', e => {
            const { box } = e.detail

            if (isOutsideContainer(box)) {
                e.preventDefault()
            } else {
                const labelCenter = calculateTextPos(type, box)
                element.label.center(labelCenter[0], labelCenter[1])
                if (element.zoomBtn) placeZoomBtn(box)
                if (element.langImg) placeLangElems(box)
            }
        })

        element.elem.on('dragend', e => {
            element.bbox = e.detail.box
            onLayoutChange(prepareLayoutData())
        })

        thatRef.current.elements.push(element)
    }

    function isOutsideContainer(bbox) {
        const { x, y, h, w } = bbox

        // top + left edges
        if (x < 0 || y < 0) {
            return true
        }

        // right + bottom edges
        if (x + w > thatRef.current.containerSize.width || y + h > thatRef.current.containerSize.height) {
            return true
        }

        return false
    }

    function shouldFlipText(connector) {
        const start = connector.node.getPointAtLength(0)
        const end = connector.node.getPointAtLength(connector.length())

        return end.x < start.x
    }

    function createConnectionFromData(connecationData) {
        const { id, sourceId, targetId, text, config } = connecationData
        const source = thatRef.current.elements.find(({ id }) => id === sourceId)
        const target = thatRef.current.elements.find(({ id }) => id === targetId)

        // https://jsfiddle.net/L2sjjc3b/80/
        const connection = source.elem.connectable({
            container: thatRef.current.draw.group(),
            markers: thatRef.current.draw.group(),
            marker: config.marker,
            sourceAttach: config.sourceAttach,
            targetAttach: config.targetAttach
        }, target.elem)

        const showLabel = thatRef.current.appearance && thatRef.current.appearance['show-labels'] === true
        const textElem = thatRef.current.draw.text(add => showLabel && add.tspan(_trimText()).dy(-4)).font('size', DEFAULTS.fontSize.path + 'px')
        const textPath = textElem.path(connection.connector)
        _transformText()

        // Used for playing the steps
        connection.textPath = textPath
        connection.textElem = textElem
        thatRef.current.connections[id] = connection

        source.elem.on('dragend', _transformText)
        target.elem.on('dragend', _transformText)

        function _trimText() {
            let _text
            const connectorLength = connection.connector.length()
            const effectiveLength = connectorLength - 30 // x-margin
            const _textElem = thatRef.current.draw.text(text).font('size', DEFAULTS.fontSize.path + 'px')
            const textLength = _textElem.length()

            if (effectiveLength > textLength) {
                _text = text
            } else {
                const charsCount = Math.floor(text.length * effectiveLength / textLength) - 3 // for the "..."
                _text = text.substr(0, charsCount) + '...'
            }

            _textElem.remove()
            return _text
        }

        function _transformText() {
            if (thatRef.current.scale !== 1) return
            textPath.attr('startOffset', (connection.connector.length() - textElem.length()) / 2)

            if (showLabel && shouldFlipText(connection.connector)) {
                textElem.flip('both').attr('data-flipped', true)
            }
        }
    }

    return (
        <div id="diagram-svg" ref={svgRef}>
            <div id='step-card-cont' style={stepCard.style}>
                <Card style={{
                    transform: `scale(${thatRef.current ? thatRef.current.scale : 1})`,
                    transformOrigin: 'top left'
                }}>
                    <Card.Body>
                        <Card.Title>{stepCard.content.title}</Card.Title>
                        <Card.Text>{stepCard.content.description}</Card.Text>

                        {stepCard.content.dataList && <ul>
                            {stepCard.content.dataList.map(item => (
                                <li key={item}>{item}</li>
                            ))}
                        </ul>}
                    </Card.Body>
                    <ShowIf cond={thatRef.current && thatRef.current.animationMetadata.mode !== 'Continuous'}>
                        <Card.Footer className='d-flex justify-content-end'>
                            <ButtonGroup size="sm">
                                <Button variant='danger' onClick={hideStepCard}>Hide</Button>
                                <Button disabled={stepCardBtn.prev.disabled} onClick={playPrevStep}>Prev</Button>
                                <Button disabled={stepCardBtn.next.disabled} onClick={playNextStep}>Next</Button>
                            </ButtonGroup>
                        </Card.Footer>
                    </ShowIf>
                </Card>
            </div>
        </div>
    )
})

export default SVGDiagram