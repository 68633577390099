import firebase from 'firebase/app';
import 'firebase/auth';
import { Redirect, Route } from "react-router-dom";

function handleSuccessfulLogin() {
    localStorage.setItem('user', 'user')
}

export function signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider()

    return firebase.auth()
        .signInWithPopup(provider)
        .then(handleSuccessfulLogin)
}

export function signInWithGitHub() {
    const provider = new firebase.auth.GithubAuthProvider()

    return firebase.auth()
        .signInWithPopup(provider)
        .then(handleSuccessfulLogin)
}

export function signin(email, password) {
    return firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then(handleSuccessfulLogin)
}

export function register(email, password) {
    return firebase.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(handleSuccessfulLogin)
}

export function onAuthChange(cb) {
    firebase.auth().onAuthStateChanged(cb)
    firebase.auth().onIdTokenChanged(cb)
}

export function sendPasswordResetEmail(email) {
    return firebase.auth().sendPasswordResetEmail(email)
}

export function PublicRoute({ children, user, ...rest }) {
    function render({ location }) {
        if (user && location.pathname === '/login') {
            return <Redirect to={{ pathname: "/", state: { from: location } }} />
        }

        return children
    }

    return <Route {...rest} render={render} />
}

export function PrivateRoute({ children, user, ...rest }) {
    function render({ location }) {
        if (user && user.claims && user.claims.active) {
            if (location.pathname === '/inactive') {
                return <Redirect to={{ pathname: "/", state: { from: location } }} />
            }

            return children
        }

        if (user && user.claims && !user.claims.active) {
            if (location.pathname === '/inactive') {
                return children
            }

            return <Redirect to={{ pathname: "/inactive", state: { from: location } }} />
        }

        return <Redirect to={{ pathname: "/login", state: { from: location } }} />
    }

    return <Route {...rest} render={render} />
}