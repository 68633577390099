import baseStructure from "./base-structure"

const structure = {
    ...baseStructure,
    steps: [{
        from: 'string',
        to: 'string',
        action: 'string',
    }]
}

export const defaultComponent = {
    name: '',
    type: '',
    appearance: {
        groups: []
    },
    steps: []
}

export default structure