import { useState, useRef, useEffect } from 'react'
import { Accordion, Badge, Button, Container, Form, ListGroup, OverlayTrigger, Popover, Spinner, Overlay, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ShowIf from '../components/show-if'
import { fetchGHCredentials, fetchGHFiles, fetchGHRepos, importFiles, loadAndValidateGHFiles, showNotification } from '../store'
import { validate } from '../components/diagram/validator'
import jsYaml from 'js-yaml'
import flowStructure from '../components/diagram/structure/flow-structure'
import componentStructure from '../components/diagram/structure/component-structure'
import { validationPopover } from '../components/common'
import { EVENTS, logEvent } from '../analytics'

const localMode = 'local'
const gitHubMode = 'github'

export default function Import() {
  const dispatch = useDispatch()
  const history = useHistory()
  const importState = useSelector(state => state.import)
  const [selectedFiles, setSelectedFiles] = useState({})
  const [selectedLocalFiles, setSelectedLocalFiles] = useState({})
  const [validatedLocalFiles, setValidatedLocalFiles] = useState({})
  const [expandedRepoId, setExpandedRepoId] = useState([])
  const [showYamlOnly, setShowYamlOnly] = useState(true)
  const [noYamlFiles, setNoYamlFiles] = useState(false)
  const [showAuthTooltip, setShowAuthTooltip] = useState(false)
  const [importMode, setImportMode] = useState(null)
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (importMode === localMode) {
      resetFiles()
    }
  }, [importMode])

  function resetFiles() {
    setNoYamlFiles(false)
    setSelectedLocalFiles({})
    setValidatedLocalFiles({})
  }

  function isYamlFile(filename) {
    return /ya?ml/gi.test(filename)
  }

  function readSelectedFiles(evt) {
    resetFiles()

    const files = Array.from(evt.target.files).filter(file => isYamlFile(file.name))
    let loadedCount = 0
    const _selectedFiles = {}

    if (files.length === 0) {
      setNoYamlFiles(true)
    }

    files.forEach(file => {
      if (isYamlFile(file.name)) {
        const name = file.name
        const reader = new FileReader()

        reader.onload = function (e) {
          var contents = e.target.result;
          _selectedFiles[name] = { contents, name }
          loadedCount++

          if (loadedCount === files.length) {
            setSelectedLocalFiles(_selectedFiles)
          }
        }

        reader.readAsText(file)
      }
    })
  }

  function validateLocalFiles() {
    const validatedFiles = Object.keys(selectedLocalFiles).reduce((acc, name) => {
      acc[name] = { ...selectedLocalFiles[name], valid: false }

      try {
        const code = jsYaml.load(acc[name].contents)
        acc[name].code = code
        acc[name].errors = validate(code, code.type === 'flow' ? flowStructure : componentStructure, code.name)
      } catch { }

      return acc
    }, {})

    logEvent(EVENTS.IMPORT.FILES.VALIDATED, { count: Object.keys(selectedLocalFiles).length, mode: importMode })
    setValidatedLocalFiles(validatedFiles)
  }

  function renderSelectLocalFileLabel(name) {
    const validatedFile = validatedLocalFiles[name]

    if (validatedFile) {
      let badge
      let popover = null

      if (validatedFile.errors.length > 0) {
        badge = <Badge pill bg="danger">Invalid Yaml</Badge>
        popover = <OverlayTrigger trigger="click" placement="top" rootClose={true} overlay={props => validationPopover(props, validatedFile.errors)}>
          <Button variant="warning" className='ms-2' size="sm">Validation Errors</Button>
        </OverlayTrigger>
      } else {
        badge = <Badge pill bg="success">Valid Yaml</Badge>
      }

      return <div>{name} {badge} {popover}</div>
    }

    return name
  }

  function handleAuth() {
    dispatch(fetchGHCredentials())
      .then(() => dispatch(fetchGHRepos()))
  }

  function handleExpandRepo(id, name) {
    setExpandedRepoId(id)
    dispatch(fetchGHFiles(name))
  }

  function yamlFiles(file) {
    if (showYamlOnly) return file.path.toLowerCase().includes('.yaml')
    return true
  }

  function handleSelectFile(checked, file) {
    const repo = importState.repos.find(({ id }) => id === expandedRepoId)
    const repoName = repo ? repo.name : ''
    const _selectedFiles = { ...selectedFiles }

    if (checked) {
      _selectedFiles[file.url] = { ...file, repoName }
    } else {
      delete _selectedFiles[file.url]
    }

    setSelectedFiles(_selectedFiles)
  }

  function handleValidate() {
    const urls = Object.keys(selectedFiles).map(url => ({ url, repoName: selectedFiles[url].repoName }))
    logEvent(EVENTS.IMPORT.FILES.VALIDATED, { count: urls.length, mode: importMode })
    dispatch(loadAndValidateGHFiles(urls))
  }

  function handleImport() {
    let files = []

    if (importMode === gitHubMode) {
      files = importState.validatedFiles.map(prepareGHFileForImport)
    } else {
      files = Object.keys(validatedLocalFiles).map(name => validatedLocalFiles[name]).map(prepareLocalFileForImport)
    }

    dispatch(importFiles(files))
      .then(() => {
        dispatch(showNotification({ message: 'Files successfully imported!', success: true }))
        logEvent(EVENTS.DIAGRAM.IMPORTED, { count: files.length, mode: importMode })
        history.push('/list')
      })
  }

  function prepareGHFileForImport(validatedFile) {
    const { code, yamlStr, repoName, url } = validatedFile

    return {
      name: code.name || '',
      layout: JSON.stringify({}),
      yamlCode: yamlStr,
      type: code.type || 'component',
      metadata: {
        'vcs': 'github',
        repoName,
        fileUrl: url
      }
    }
  }

  function prepareLocalFileForImport(validatedFile) {
    const { contents, name, code } = validatedFile

    return {
      name: code.name || '',
      layout: JSON.stringify({}),
      yamlCode: contents,
      type: code.type || 'component',
      metadata: {
        'vcs': 'local',
        fileUrl: 'C:\\' + name
      }
    }
  }

  function renderSelectFileLabel(file) {
    const repoAndPath = file.repoName + ' / ' + file.path
    const validatedFile = importState.validatedFiles.find(({ url }) => url === file.url)

    if (validatedFile) {
      let badge
      let popover = null

      if (validatedFile.errors.length > 0) {
        badge = <Badge pill bg="danger">Invalid Yaml</Badge>
        popover = <OverlayTrigger trigger="click" placement="top" overlay={props => validationPopover(props, validatedFile.errors)}>
          <Button variant="warning" className='ms-2' size="sm">Validation Errors</Button>
        </OverlayTrigger>
      } else {
        badge = <Badge pill bg="success">Valid Yaml</Badge>
      }

      return <div>{repoAndPath} {badge} {popover}</div>
    }

    if (importState.validatingFiles) {
      return <div>{repoAndPath} <Spinner animation="border" size='sm' /></div>
    }

    return repoAndPath
  }

  function importPopover(props) {
    function title() {
      const invalidFiles = importMode === gitHubMode ?
        importState.validatedFiles.find(file => file.errors.length > 0) :
        Object.keys(validatedLocalFiles).find(name => validatedLocalFiles[name].errors.length > 0)

      if (invalidFiles) {
        return 'Are you sure? Some files are invalid!'
      }

      return 'Are you sure?'
    }

    return (
      <Popover id="popover-basic" {...props}>
        <Popover.Header as="h3">{title()}</Popover.Header>
        <Popover.Body className='text-center'>
          <Button onClick={handleImport} size='sm' variant='success'>Yes, Import All</Button>
        </Popover.Body>
      </Popover>
    )
  }

  function handleSelectMode(mode) {
    setImportMode(mode)
    logEvent(EVENTS.IMPORT.MODE_SWITCHED, { mode })
  }

  return (
    <Container id="import" className='mb-5'>
      <ShowIf cond={importState.loading}>
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      </ShowIf>

      <div className='mt-5 mb-5 text-center'>
        <Button onClick={() => handleSelectMode(localMode)} className='me-2' size='sm' variant='primary'><i className="bi bi-folder-symlink-fill"></i> Browser Local Files</Button>
        <Button onClick={() => handleSelectMode(gitHubMode)} size='sm' variant='dark'><i className="bi bi-github"></i> Fetch Files from GitHub</Button>
      </div>

      <ShowIf cond={importMode}>
        <hr />
      </ShowIf>

      <ShowIf cond={importMode === localMode}>
        <div className='text-center mt-5'>
          <Form.Control type="file" multiple onChange={readSelectedFiles} />
        </div>

        <ShowIf cond={noYamlFiles}>
          <div className='text-center mt-5'>
            You did not select any Yaml files.
          </div>
        </ShowIf>

        <ShowIf cond={Object.keys(selectedLocalFiles).length}>
          <h5 className='mt-5'>Validate and Import</h5>
          <Accordion className='mt-3'>
            <Accordion.Item eventKey='selectedLocalFiles'>
              <Accordion.Header>Selected files (yaml files only)</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {Object.keys(selectedLocalFiles).map(name =>
                    <ListGroup.Item key={name}>
                      {renderSelectLocalFileLabel(name)}
                    </ListGroup.Item>
                  )}
                </ListGroup>

                <div className="d-flex justify-content-end mt-2">
                  <ShowIf cond={Object.keys(validatedLocalFiles).length > 0}>
                    <OverlayTrigger trigger="click" placement="top" rootClose={true} overlay={importPopover}>
                      <Button size='sm'>Import</Button>
                    </OverlayTrigger>
                    <div className="vr mx-2" />
                  </ShowIf>
                  <Button onClick={validateLocalFiles} size='sm'>Validate</Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ShowIf>
      </ShowIf>

      <ShowIf cond={importMode === gitHubMode}>
        <ShowIf cond={!importState.credentials}>
          <div className='text-center mt-5'>
            Authorize to fetch a temporary access token <Button onClick={handleAuth} size='sm' variant='success'>Authorize</Button>
            <i className="bi bi-info-circle ms-2" onClick={() => setShowAuthTooltip(!showAuthTooltip)} ref={tooltipRef}></i>

            <Overlay target={tooltipRef.current} show={showAuthTooltip} placement="right">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  <div className='px-1 py-2'>
                    We do not store your GitHub credentials or tokens.
                    <br /><br />
                    Authorization allows us to fetch the temporary access token to import files from your Github account.
                  </div>
                </Tooltip>
              )}
            </Overlay>

            <ShowIf cond={importState.loadingCredentials}>
              <div className='text-center mt-5'>
                <Spinner animation="border" />
              </div>
            </ShowIf>
          </div>
        </ShowIf>

        <ShowIf cond={importState.credentials}>
          <h5 className='mt-5'>Step 1: Select Files to Import</h5>
          <ShowIf cond={importState.repos.length === 0}>
            <p className='text-center mt-2'>We could not fetch any repositories. There is probably something wrong!</p>
          </ShowIf>

          <Accordion>
            {importState.repos.map(({ id, name }) => (
              <Accordion.Item eventKey={id} key={id}>
                <Accordion.Header onClick={() => handleExpandRepo(id, name)}>{name}</Accordion.Header>
                <Accordion.Body>
                  <ShowIf cond={expandedRepoId === id && importState.loadingFiles}>
                    <div className="text-center my-5">
                      <Spinner animation="border" />
                    </div>
                  </ShowIf>

                  <ShowIf cond={!importState.loadingFiles && importState.files.length > 0}>
                    <ShowIf cond={importState.files.length > 0}>
                      <Form.Group className="mb-3" controlId="showYamlOnly">
                        <Form.Check
                          type="switch"
                          checked={showYamlOnly}
                          label="Yaml files only?"
                          onChange={() => setShowYamlOnly(!showYamlOnly)}
                        />
                      </Form.Group>
                    </ShowIf>

                    <ShowIf cond={importState.files.length === 0}>
                      <p className='text-center my-2'>No files were found in the repository.</p>
                    </ShowIf>

                    <ListGroup>
                      {importState.files.filter(yamlFiles).map(file =>
                        <ListGroup.Item key={file.url}>
                          <Form.Group controlId={file.url}>
                            <Form.Check
                              type="checkbox"
                              checked={selectedFiles[file.url]}
                              label={file.path}
                              onChange={evt => handleSelectFile(evt.target.checked, file)}
                            />
                          </Form.Group>
                        </ListGroup.Item>
                      )}
                    </ListGroup>

                    <ShowIf cond={showYamlOnly && importState.files.filter(yamlFiles).length === 0}>
                      <p className='text-center my-2'>No Yaml files in this repository.</p>
                    </ShowIf>
                  </ShowIf>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>

          <ShowIf cond={Object.keys(selectedFiles).length}>
            <h5 className='mt-5'>Step 2: Validate and Import</h5>
            <Accordion className='mt-3'>
              <Accordion.Item eventKey='selectedFiles'>
                <Accordion.Header>Selected files</Accordion.Header>
                <Accordion.Body>
                  <ListGroup>
                    {Object.keys(selectedFiles).map(url =>
                      <ListGroup.Item key={url}>
                        <Form.Group controlId={url}>
                          <Form.Check
                            type="checkbox"
                            checked={selectedFiles[url]}
                            label={renderSelectFileLabel(selectedFiles[url])}
                            onChange={evt => handleSelectFile(evt.target.checked, selectedFiles[url])}
                          />
                        </Form.Group>
                      </ListGroup.Item>
                    )}
                  </ListGroup>

                  <div className="d-flex justify-content-end mt-2">
                    <ShowIf cond={importState.validatedFiles.length > 0}>
                      <OverlayTrigger trigger="click" placement="top" overlay={importPopover} rootClose={true}>
                        <Button size='sm'>Import</Button>
                      </OverlayTrigger>
                      <div className="vr mx-2" />
                    </ShowIf>
                    <Button onClick={handleValidate} size='sm'>Validate</Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </ShowIf>
        </ShowIf>
      </ShowIf>

    </Container>
  )
}