import { useEffect, useRef } from 'react';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import { EVENTS, logEvent } from '../analytics';
import { fetchComponents, fetchFlows, resetComponent, resetFlow, signout } from '../store';
import ShowIf from './show-if';

export default function Header() {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const navigatorRef = useRef()
    const userState = useSelector(state => state.user)
    const flowsState = useSelector(state => state.flows)
    const componentsState = useSelector(state => state.components)

    useEffect(() => {
        if (userState.current && userState.current.claims.active) {
            dispatch(fetchFlows())
            dispatch(fetchComponents())
        }
    }, [userState])

    function goToLoginIn() {
        history.push('/login')
    }

    function handleSignout() {
        dispatch(signout())
            .then(() => {
                localStorage.removeItem('user')
                logEvent(EVENTS.SIGNOUT.SIGNEDOUT)
            })
            .catch((err) => {
                logEvent(EVENTS.SIGNOUT.FAILED, { error: err.message })
            })

    }

    function prepareNavOptions() {
        const options = []

        // TODO: fix me. The is a race condition after signing out.
        if (flowsState.flows && componentsState.components) {
            flowsState.flows.forEach(({ id, name }) => options.push({ id, to: '/flows/' + id, label: 'flow/' + name }))
            componentsState.components.forEach(({ id, name }) => options.push({ id, to: '/components/' + id, label: 'component/' + name }))
        }

        return options
    }

    function handleSelectNav(selected) {
        if (selected[0]) {
            navigatorRef.current.clear()
            history.push(selected[0].to)
            navigatorRef.current.blur()
            dispatch(resetFlow())
            dispatch(resetComponent())
        }
    }

    function goToCreatePage(key) {
        history.push('/' + key + '/new')
    }

    const profileDropdownTitle = userState.current && userState.current.photoURL ?
        <img src={userState.current.photoURL} alt="mdo" width="25" height="25" className="rounded-circle" /> :
        <i className="profile-icon bi bi-person-circle"></i>

    return (
        <Navbar id={location.pathname === '/' ? 'landing' : ''} className="mb-3" expand="lg">
            <Container fluid>
                <Link className="navbar-brand" to="/">
                    <img id='header-logo' src='logo-compact-light.png' alt='arch-explained-logo' />
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <ShowIf cond={userState.current}>
                        <Nav className="me-auto">
                            <ShowIf cond={userState.current && userState.current.claims && userState.current.claims.active}>
                                <Typeahead
                                    id="navigate-to"
                                    className='navigator'
                                    size='sm'
                                    placeholder='Navigate to'
                                    onChange={handleSelectNav}
                                    options={prepareNavOptions()}
                                    ref={navigatorRef}
                                />
                            </ShowIf>

                            <Link className="ms-2 nav-link" to="/list">List</Link>

                            <NavDropdown title="Create" id="create-dropdown" onSelect={goToCreatePage}>
                                <NavDropdown.Item eventKey="flows">Flow</NavDropdown.Item>
                                <NavDropdown.Item eventKey="components">Component</NavDropdown.Item>
                            </NavDropdown>

                            <Link className="nav-link" to="/import">Import</Link>
                        </Nav>
                    </ShowIf>
                    <Nav>
                        <Link className="nav-link" to="/about">About</Link>
                        <Link className="nav-link" to="/faqs">FAQs</Link>
                        <Link className="nav-link" to="/documentation">Documentation</Link>

                        {userState.current ?
                            <span className='align-right'>
                                <NavDropdown className='profile-dropdown' title={profileDropdownTitle}>
                                    <NavDropdown.Item disabled>
                                        <p>{userState.current.displayName || userState.current.email}</p>
                                        {userState.current.displayName && <small>{userState.current.email}</small>}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={handleSignout}>Sign Out</NavDropdown.Item>
                                </NavDropdown>
                            </span> :

                            <Button variant="primary" className='btn-sm' onClick={goToLoginIn}>Log In</Button>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}