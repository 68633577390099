import { configureStore, createAsyncThunk, createSlice, combineReducers } from '@reduxjs/toolkit';
import * as FB from './firebase';
import * as importService from './import-service';

export const signout = createAsyncThunk('signout', async () => await FB.signout())
export const fetchUserClaimsAndSetUser = createAsyncThunk('fetchUserClaimsAndSetUser', async (user) => await FB.fetchUserClaimsAndSetUser(user))

export const fetchFlows = createAsyncThunk('fetchFlows', async () => await FB.fetchFlows())
export const fetchFlowById = createAsyncThunk('fetchFlowById', async (id) => await FB.fetchFlowById(id))
export const updateFlow = createAsyncThunk('updateFlow', async ([id, diagram, imageBlob]) => await FB.updateFlow(id, diagram, imageBlob))
export const createFlow = createAsyncThunk('createFlow', async ([diagram, imageBlob]) => await FB.createFlow(diagram, imageBlob))
export const deleteFlow = createAsyncThunk('deleteFlow', async (id) => await FB.deleteFlow(id))

export const fetchComponents = createAsyncThunk('fetchComponents', async (id) => await FB.fetchComponents(id))
export const fetchComponentById = createAsyncThunk('fetchComponentById', async (id) => await FB.fetchComponentById(id))
export const updateComponent = createAsyncThunk('updateComponent', async ([id, component, imageBlob]) => await FB.updateComponent(id, component, imageBlob))
export const createComponent = createAsyncThunk('createComponent', async ([component, imageBlob]) => await FB.createComponent(component, imageBlob))
export const deleteComponent = createAsyncThunk('deleteComponent', async (id) => await FB.deleteComponent(id))

export const fetchGHFiles = createAsyncThunk('fetchGHFiles', async (repoName) => await importService.fetchFiles(repoName))
export const fetchGHRepos = createAsyncThunk('fetchGHRepos', async () => await importService.fetchRepos())
export const importFiles = createAsyncThunk('importGHFiles', async (files) => await importService.importFiles(files))
export const fetchGHCredentials = createAsyncThunk('fetchGHCredentials', async () => await importService.fetchCredentials())
export const loadAndValidateGHFiles = createAsyncThunk('loadAndValidateGHFiles', async (files) => await importService.loadAndValidateGHFiles(files))

const emptyDiagram = { layout: '{}', yamlCode: '' }

const initialState = {
  user: {
    loading: true,
    current: null,
  },
  flows: {
    flows: [],
    flow: emptyDiagram,
    loading: false,
  },
  components: {
    components: [],
    component: emptyDiagram,
    loading: false,
    error: null,
  },
  import: {
    repos: [],
    loading: false,

    loadingCredentials: false,
    credentials: null,

    files: [],
    loadingFiles: false,

    importedFiles: [],
    importingFiles: false,

    validatedFiles: [],
    validatingFiles: false,
  },
  notification: {
    message: '',
    visible: false,
    success: false,
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState.user,
  reducers: {
    setCurrentUser(state, action) {
      state.current = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(signout.pending, state => {
      state.loading = true
    })
    builder.addCase(signout.fulfilled, state => {
      // state.loading = false
      // state.current = null
    })
    builder.addCase(signout.rejected, state => {
      state.loading = false
      console.error('signout.rejected')
    })

    builder.addCase(fetchUserClaimsAndSetUser.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchUserClaimsAndSetUser.fulfilled, (state, action) => {
      state.loading = false
      state.current = action.payload
    })
    builder.addCase(fetchUserClaimsAndSetUser.rejected, state => {
      state.loading = false
      console.error('fetchUserClaimsAndSetUser.rejected')
    })
  }
})

const flowsSlice = createSlice({
  name: 'diagrams',
  initialState: initialState.flows,
  reducers: {
    resetFlow(state) {
      state.flow = emptyDiagram
    }
  },
  extraReducers: builder => {
    // Fetch all
    builder.addCase(fetchFlows.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchFlows.fulfilled, (state, action) => {
      state.flows = action.payload
      state.loading = false
    })
    builder.addCase(fetchFlows.rejected, state => {
      state.loading = false
      console.error('fetchFlows.rejected')
    })

    // Fetch by id
    builder.addCase(fetchFlowById.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchFlowById.fulfilled, (state, action) => {
      state.flow = action.payload
      state.loading = false
    })
    builder.addCase(fetchFlowById.rejected, state => {
      state.loading = false
      console.error('fetchFlowById.rejected')
    })

    // Create
    builder.addCase(createFlow.pending, state => {
      state.loading = true
    })
    builder.addCase(createFlow.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(createFlow.rejected, state => {
      state.loading = false
      console.error('createFlow.rejected')
    })

    // Update
    builder.addCase(updateFlow.pending, state => {
      state.loading = true
    })
    builder.addCase(updateFlow.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(updateFlow.rejected, state => {
      state.loading = false
      console.error('updateFlow.rejected')
    })

    // Delete
    builder.addCase(deleteFlow.pending, state => {
      state.loading = true
    })
    builder.addCase(deleteFlow.fulfilled, (state) => {
      state.flow = emptyDiagram
      state.loading = false
    })
    builder.addCase(deleteFlow.rejected, state => {
      state.loading = false
      console.error('deleteFlow.rejected')
    })
  }
})

const componentsSlice = createSlice({
  name: 'components',
  initialState: initialState.components,
  reducers: {
    resetComponent(state) {
      state.component = emptyDiagram
    }
  },
  extraReducers: builder => {
    // Fetch all
    builder.addCase(fetchComponents.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchComponents.fulfilled, (state, action) => {
      state.components = action.payload
      state.loading = false
    })
    builder.addCase(fetchComponents.rejected, state => {
      state.loading = false
      console.error('fetchComponents.rejected')
    })

    // Fetch by id
    builder.addCase(fetchComponentById.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchComponentById.fulfilled, (state, action) => {
      state.component = action.payload
      state.loading = false
    })
    builder.addCase(fetchComponentById.rejected, state => {
      state.loading = false
      console.error('fetchComponentById.rejected')
    })

    // Create
    builder.addCase(createComponent.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createComponent.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(createComponent.rejected, (state, { error }) => {
      state.loading = false
      state.error = error.message
      console.error('createComponent.rejected', error)
    })

    // Update
    builder.addCase(updateComponent.pending, state => {
      state.loading = true
    })
    builder.addCase(updateComponent.fulfilled, (state, action) => {
      state.component = action.payload
      state.loading = false
    })
    builder.addCase(updateComponent.rejected, state => {
      state.loading = false
      console.error('updateComponent.rejected')
    })

    // Delete
    builder.addCase(deleteComponent.pending, state => {
      state.loading = true
    })
    builder.addCase(deleteComponent.fulfilled, (state) => {
      state.diagram = emptyDiagram
      state.loading = false
    })
    builder.addCase(deleteComponent.rejected, state => {
      state.loading = false
      console.error('deleteComponent.rejected')
    })
  }
})

const importSlice = createSlice({
  name: 'import',
  initialState: initialState.import,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchGHCredentials.pending, state => {
      state.loadingCredentials = true
    })
    builder.addCase(fetchGHCredentials.fulfilled, (state, action) => {
      state.credentials = action.payload
      state.loadingCredentials = false
    })
    builder.addCase(fetchGHCredentials.rejected, state => {
      state.loadingCredentials = false
      console.error('fetchGHCredentials.rejected')
    })

    builder.addCase(fetchGHFiles.pending, state => {
      state.loadingFiles = true
    })
    builder.addCase(fetchGHFiles.fulfilled, (state, action) => {
      state.files = action.payload || []
      state.loadingFiles = false
      state.validatedFiles = []
      state.importedFiles = []
    })
    builder.addCase(fetchGHFiles.rejected, state => {
      state.loadingFiles = false
      console.error('fetchGHFiles.rejected')
    })

    builder.addCase(fetchGHRepos.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchGHRepos.fulfilled, (state, action) => {
      state.repos = action.payload
      state.loading = false
      state.files = []
      state.validatedFiles = []
      state.importedFiles = []
    })
    builder.addCase(fetchGHRepos.rejected, state => {
      state.loading = false
      console.error('fetchGHRepos.rejected')
    })

    builder.addCase(importFiles.pending, state => {
      state.importingFiles = true
    })
    builder.addCase(importFiles.fulfilled, (state, action) => {
      state.importedFiles = action.payload
      state.importingFiles = false
    })
    builder.addCase(importFiles.rejected, state => {
      state.importingFiles = false
      console.error('importFiles.rejected')
    })

    builder.addCase(loadAndValidateGHFiles.pending, state => {
      state.validatingFiles = true
      state.validatedFiles = []
      state.importedFiles = []
    })
    builder.addCase(loadAndValidateGHFiles.fulfilled, (state, action) => {
      state.validatedFiles = action.payload
      state.validatingFiles = false
    })
    builder.addCase(loadAndValidateGHFiles.rejected, state => {
      state.validatingFiles = false
      console.error('loadAndValidateGHFiles.rejected')
    })
  }
})

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState.components,
  reducers: {
    showNotification(state, action) {
      state.visible = true
      state.message = action.payload.message
      state.success = action.payload.success

      setTimeout(() => store.dispatch(hideNotification()), 4000)
    },
    hideNotification(state) {
      state.visible = false
      state.message = ''
      state.success = false
    }
  }
})

export const { resetFlow } = flowsSlice.actions
export const { resetComponent } = componentsSlice.actions
export const { setCurrentUser } = userSlice.actions
export const { showNotification, hideNotification } = notificationSlice.actions

const combinedReducer = combineReducers({
  user: userSlice.reducer,
  flows: flowsSlice.reducer,
  components: componentsSlice.reducer,
  notification: notificationSlice.reducer,
  import: importSlice.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'signout/fulfilled') {
    // state = initialState
  }

  return combinedReducer(state, action);
}

export const store = configureStore({
  reducer: rootReducer
})