import { useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EVENTS, logEvent } from '../analytics';
import { register, signin, signInWithGitHub, signInWithGoogle } from '../auth';
import ShowIf from '../components/show-if';

const signIn = 'signin'
const resigter = 'resigter'

export default function LoginPage() {
    const [formType, setFormType] = useState(null)
    const [loginError, setLoginError] = useState(null)

    function onSubmit(event) {
        event.preventDefault()

        const data = new FormData(event.target)
        const email = data.get('email')
        const password = data.get('password')

        if (formType === signIn) {
            signin(email, password)
                .then(() => logEvent(EVENTS.LOGIN.LOGGEDIN, {provider: 'Email/Password'}))
                .catch(handleLogInError)
        } else if (formType === resigter) {
            register(email, password)
                .then(() => logEvent(EVENTS.SIGNUP.SIGNEDUP, {provider: 'Email/Password'}))
                .catch(handleLogInError)
        }
    }

    function handleProviderSignIn(provider) {
        let promise

        if (provider === 'google') {
            promise = signInWithGoogle()
        }

        if (provider === 'github') {
            promise = signInWithGitHub()
        }

        if (promise) {
            promise
                .then(() => logEvent(EVENTS.LOGIN.LOGGEDIN, { provider }))
                .catch(handleLogInError)
        }
    }

    function handleLogInError(err) {
        setLoginError(err.message)
        logEvent(EVENTS.LOGIN.FAILED, { error: err.message })
    }

    return (
        <Container id="login">
            <figure className='text-center mb-5'>
                <h1 className="display-1 mt-5 mb-4">
                    <img id='display-logo' src='logo.png' alt='arch-explained-logo' />
                </h1>
            </figure>

            <ShowIf cond={loginError}>
                <Alert variant="danger" onClose={() => setLoginError(null)} dismissible>{loginError}</Alert>
            </ShowIf>

            <Form onSubmit={onSubmit}>
                <Form.Group controlId="email" className='mb-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Your email" name="email" />
                </Form.Group>

                <Form.Group controlId="password" className='mb-3'>
                    <Form.Label>Password</Form.Label>
                    <Link className='float-end' to="/password-reset"><small>Forgot Password?</small></Link>
                    <Form.Control type="password" placeholder="Your password" name="password" />
                </Form.Group>

                <div className='text-center'>
                    <div className='text-start d-inline-block'>
                        <Button variant="primary" type="submit" onClick={() => setFormType(signIn)}>Log In</Button>
                        <span className='mx-3'>or</span>
                        <Button variant="primary" type="submit" onClick={() => setFormType(resigter)}>Register</Button>

                    </div>
                </div>
            </Form>

            <div className="d-flex my-4">
                <hr className="my-auto flex-grow-1" />
                <div className="px-4">OR</div>
                <hr className="my-auto flex-grow-1" />
            </div>

            <div className='text-center'>
                <Button variant="danger" className='me-2' onClick={() => handleProviderSignIn('google')}>
                    <i className="bi bi-google"></i> Login with Google
                </Button>
                <Button variant="dark" onClick={() => handleProviderSignIn('github')}>
                    <i className="bi bi-github"></i> Login with GitHub
                </Button>
            </div>
        </Container>
    )
}