import jsYaml from 'js-yaml'
import { useEffect, useState } from 'react'
import { Badge, Button, Offcanvas, OverlayTrigger } from 'react-bootstrap'
import MonacoEditor from 'react-monaco-editor'
import { validate } from '../components/diagram/validator'
import { validationPopover } from './common'
import { logEvent, EVENTS } from '../analytics'

export default function Editor({ yamlCode, structure, onChange, show, onHide, template, onSave }) {
    const [saveRequested, setSaveRequested] = useState(false)
    const [validationErrors, setValidationErrors] = useState([])

    useEffect(() => {
        try {
            const loaded = jsYaml.load(yamlCode)
            if (yamlCode) {
                setValidationErrors(validate(loaded, structure))
            }
        } catch { }
    }, [yamlCode, structure])

    useEffect(() => {
        if (saveRequested) {
            setSaveRequested(false)
            if (onSave) onSave()
        }
    }, [saveRequested, onSave])

    function loadTemplate() {
        if (yamlCode && !window.confirm('Are you sure? All you code will be overwritten.')) {
            return
        }

        onChange(template)
    }

    function editorDidMount(editor, monaco) {
        editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
            logEvent(EVENTS.EDITOR.SAVED)
            setSaveRequested(true)
        })
    }

    return (
        <Offcanvas show={show} onHide={onHide}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    Code Editor
                    <Button className='ms-2' size="sm" onClick={loadTemplate}>Load Template</Button>

                    {validationErrors.length ?
                        <OverlayTrigger trigger="click" placement="bottom" rootClose={true} overlay={props => validationPopover(props, validationErrors)}>
                            <Button variant="warning" className='ms-2' size="sm">Check Yaml Errors</Button>
                        </OverlayTrigger> :
                        <Badge pill bg="success" className='ms-2'>
                            <i className="bi bi-check-circle me-1"></i> Valid Yaml
                        </Badge>
                    }
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <MonacoEditor
                    width="100%"
                    height="100%"
                    language="yaml"
                    theme="vs-dark"
                    value={yamlCode}
                    options={{}}
                    onChange={onChange}
                    editorDidMount={editorDidMount}
                />
            </Offcanvas.Body>
        </Offcanvas>
    )
}