import jsYaml from 'js-yaml';
import { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Container, Dropdown, DropdownButton, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { EVENTS, logEvent } from '../../analytics';
import Editor from '../editor';
import FullScreen from '../full-screen/full-screen';
import ShowIf from '../show-if';
import componentTemplate from './component-template.yaml';
import flowTemplate from './flow-template.yaml';
import { toDotFormat } from './grid-2';
import componentStructure, { defaultComponent } from './structure/component-structure';
import flowStructure, { defaultFlow } from './structure/flow-structure';
import './styles.css';
import SVGDiagram from './svg-diagram';

export default function EditorDiagram(props) {
    const svgDiagramRef = useRef()
    const [diagramName, setDiagramName] = useState('')
    const [yamlCode, setYamlCode] = useState('')
    const [showEditor, setShowEditor] = useState(false)
    const [fullscreenEnabled, setFullscreenEnabled] = useState(false)
    const [scenarios, setScenarios] = useState([])
    const [components, setComponents] = useState([])
    const [diagramData, setDiagramData] = useState({})
    const [svgData, setSVGData] = useState({})
    const [scenarioName, setScenarioName] = useState(null)
    const [svgLayout, setSVGLayout] = useState({})
    const [appearance, setAppearance] = useState({})
    const [prevDiagrams, setPrevDiagrams] = useState([])
    const [stepsBtn, setStepsBtn] = useState({ variant: 'primary', text: 'Play', others: [] })
    const [speedBtn, setSpeedBtn] = useState({ speed: 'Normal', others: ['Slow', 'Fast'] })
    const [modeBtn, setModeBtn] = useState({ mode: 'Step by Step', others: ['Continuous'] })
    const [zoomInOutStatus, setZoomInOutStatus] = useState({ in: true, out: true })

    let structure, template

    if (props.diagramType === 'flow') {
        structure = flowStructure
        template = flowTemplate
    }

    if (props.diagramType === 'component') {
        structure = componentStructure
        template = componentTemplate
    }

    useEffect(() => {
        try {
            const layout = JSON.parse(props.layoutString)

            setComponents(prepareComponents(props.components))
            setSVGLayout(layout)
            handleCodeChange(props.yamlCode)
        } catch (e) {
            console.error('Invalid Yaml or Layout:', e)
        }
        // eslint-disable-next-line
    }, [props.yamlCode, props.layoutString, props.components])

    useEffect(() => {
        if (props.diagramType === 'flow') {
            setScenarioName(scenarios[0] && scenarios[0].name)
            selectScenarioByName(scenarioName || (scenarios[0] && scenarios[0].name))
        }
        // eslint-disable-next-line
    }, [scenarios])

    function handleCodeChange(yamlCode) {
        setYamlCode(yamlCode)

        if (yamlCode) {
            let diagramData
            let loaded

            try {
                loaded = jsYaml.load(yamlCode)
            } catch {
                loaded = {}
            }

            if (props.diagramType === 'flow') {
                diagramData = { ...defaultFlow, ...loaded }
                setScenarios(diagramData.scenarios)
            }

            if (props.diagramType === 'component') {
                diagramData = { ...defaultComponent, ...loaded }
                setSVGData(diagramData)
            }

            setDiagramData(diagramData)
            setAppearance(diagramData.appearance)
            setDiagramName(diagramData.name)
        }

        // Receiving empty code means clearing the diagram 
        else {
            setScenarioName(null)
            setDiagramData({})
            setScenarios([])
            setAppearance({})
            setDiagramName('')
            setSVGData({})
            setPrevDiagrams([])
        }
    }

    const handleComponentZoom = (component, prevDiagram) => {
        const { layout, data, name } = component
        setSVGData(data)
        setSVGLayout(layout)
        setAppearance(data.appearance)
        setPrevDiagrams(prevDiagrams.concat({ ...prevDiagram, name: diagramName }))
        setDiagramName(name)
        logEvent(EVENTS.DIAGRAM.LEVEL.IN, { level: prevDiagrams.length })
    }

    function onPrevDiagram() {
        if (prevDiagrams.length === 0) {
            console.error('This should not happen!')
        }

        const _prevDiagram = prevDiagrams[prevDiagrams.length - 1]
        const { layout, data, name, appearance } = _prevDiagram

        setSVGData(data)
        setSVGLayout(layout)
        setDiagramName(name)
        setAppearance(appearance)
        setPrevDiagrams(prevDiagrams.slice(0, -1))
        logEvent(EVENTS.DIAGRAM.LEVEL.OUT, { level: prevDiagrams.length })
    }

    function prepareComponents(components) {
        const preparedComponents = []

        try {
            components.reduce((acc, component) => {
                acc.push({
                    name: component.name,
                    layout: JSON.parse(component.layout),
                    data: jsYaml.load(component.yamlCode),
                })

                return acc
            }, preparedComponents)
        } catch (e) {
            console.error('Error preparing components:', e)
        }

        return preparedComponents
    }

    function handleSelectScenario(name) {
        setScenarioName(name)
        selectScenarioByName(name)
        logEvent(EVENTS.DIAGRAM.SCENARIO_SELECTED)
    }

    function selectScenarioByName(name) {
        setSVGData(scenarios.find(_ => _.name === name) || {})
    }

    function handleAnimationComplete() {
        setStepsBtn({ variant: 'primary', text: 'Play' })
        logEvent(EVENTS.DIAGRAM.ANIMATION.COMPLETED)
    }

    function handleStepsBtn(text) {
        switch (text) {
            case 'Play':
                svgDiagramRef.current.playSteps(speedBtn.speed, modeBtn.mode)
                setStepsBtn({
                    variant: 'warning', text: 'Pause', others: [
                        { variant: 'danger', text: 'Reset' }
                    ]
                })
                logEvent(EVENTS.DIAGRAM.ANIMATION.PLAYED)
                break
            case 'Pause':
                svgDiagramRef.current.pauseSteps()
                setStepsBtn({
                    variant: 'primary', text: 'Play', others: [
                        { variant: 'danger', text: 'Reset' }
                    ]
                })
                logEvent(EVENTS.DIAGRAM.ANIMATION.PAUSED)
                break
            default:
                svgDiagramRef.current.stopSteps()
                setStepsBtn({ variant: 'primary', text: 'Play' })
                logEvent(EVENTS.DIAGRAM.ANIMATION.RESETED)
        }

    }

    function renderStepsBtn() {
        const { variant, text, others } = stepsBtn

        if (text === 'Play') {
            return <Button variant={variant} size="sm" onClick={() => handleStepsBtn(text)}>{text}</Button>
        }

        return (
            <ButtonGroup>
                <Button variant={variant} size="sm" onClick={() => handleStepsBtn(text)}>{text}</Button>
                <DropdownButton variant={variant} size="sm" as={ButtonGroup} title='' id="steps-dropdown">
                    {others.map(btn =>
                        <Dropdown.Item key={btn.text} eventKey="1" onClick={() => handleStepsBtn(btn.text)}>{btn.text}</Dropdown.Item>
                    )}
                </DropdownButton>
            </ButtonGroup>
        )
    }

    function handleSpeedBtn(speed) {
        switch (speed) {
            case 'Normal':
                setSpeedBtn({ speed, others: ['Fast', 'Slow'] })
                break
            case 'Fast':
                setSpeedBtn({ speed, others: ['Normal', 'Slow'] })
                break
            default:
                setSpeedBtn({ speed, others: ['Normal', 'Fast'] })
        }

        logEvent(EVENTS.DIAGRAM.ANIMATION.SPEED_CHANGED, { speed })

        // It's playing, apply the new speed
        // TODO: pausing and replaying is a hack!
        if (stepsBtn.text === 'Pause') {
            svgDiagramRef.current.pauseSteps()
            svgDiagramRef.current.playSteps(speed, modeBtn.mode)
        }
    }

    function handleModeBtn(mode) {
        switch (mode) {
            case 'Continuous':
                setModeBtn({ mode, others: ['Step By Step'] })
                break
            case 'Step By Step':
            default:
                setModeBtn({ mode, others: ['Continuous'] })
        }

        logEvent(EVENTS.DIAGRAM.ANIMATION.MODE_CHANGED, { mode })

        // It's playing, apply the new mode
        // TODO: pausing and replaying is a hack!
        if (stepsBtn.text === 'Pause') {
            svgDiagramRef.current.pauseSteps()
            svgDiagramRef.current.playSteps(speedBtn.speed, mode)
        }
    }

    function renderSpeedBtn() {
        const { speed, others } = speedBtn

        return (
            <DropdownButton variant='outline-primary' size="sm" as={ButtonGroup} title={'Speed: ' + speed} id="speed-dropdown">
                {others.map(speed =>
                    <Dropdown.Item key={speed} eventKey="1" onClick={() => handleSpeedBtn(speed)}>{speed}</Dropdown.Item>
                )}
            </DropdownButton>
        )
    }

    function renderModeBtn() {
        const { mode, others } = modeBtn

        return (
            <DropdownButton variant='outline-primary' size="sm" as={ButtonGroup} title={'Mode: ' + mode} id="mode-dropdown">
                {others.map(mode =>
                    <Dropdown.Item key={mode} eventKey="1" onClick={() => handleModeBtn(mode)}>{mode}</Dropdown.Item>
                )}
            </DropdownButton>
        )
    }

    function saveAndMaybeDownload(download) {
        logEvent(EVENTS.DIAGRAM.SAVED, { type: download ? 'SaveAndDownload' : 'SaveOnly' })

        svgDiagramRef.current.buildLayoutAndImage()
            .then(({ imageBlob }) => {
                return props.onSave({
                    name: diagramName,
                    layout: JSON.stringify(svgLayout),
                    yamlCode,
                }, imageBlob)
            })
            .then(() => {
                if (download) {
                    const filename = diagramName + '.yaml'
                    const file = new Blob([yamlCode], { type: 'text/plain' })

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        return window.navigator.msSaveOrOpenBlob(file, filename)
                    }

                    const a = document.createElement("a")
                    const url = URL.createObjectURL(file);

                    a.href = url
                    a.download = filename
                    document.body.appendChild(a)

                    setTimeout(function () {
                        a.click()
                        document.body.removeChild(a)
                        window.URL.revokeObjectURL(url)
                    }, 0)
                }
            })
            .catch(console.error)
    }

    function descPopover(props) {
        return (
            <Popover id="popover-basic" {...props}>
                <Popover.Body>
                    {diagramData.description}
                </Popover.Body>
            </Popover>
        )
    }

    function savePopover(props) {
        return (
            <Popover id="save-popover" {...props}>
                <Popover.Body className='text-center'>
                    <Button className='me-2' size='sm' variant='outline-success' onClick={() => saveAndMaybeDownload()}>Save Only</Button>
                    <Button size='sm' variant='outline-success' onClick={() => saveAndMaybeDownload(true)}>Save & Download</Button>
                </Popover.Body>
            </Popover>
        )
    }

    function saveTooltip(props) {
        const msg = !yamlCode ? "Can not save an empty diagram" : "Can not save from inside other components"

        return (
            <Tooltip id="save-tooltip" className='text-nowrap' {...props}>
                {msg}
            </Tooltip>
        )
    }

    function renderDiagramName() {
        const prevPath = prevDiagrams.map(_ => _.name).join(' / ')
        return (prevPath ? prevPath + ' / ' : '') + diagramName
    }

    function handleLayoutChange(layout) {
        setSVGLayout({ ...svgLayout, ...layout })
    }

    function handleExitFS() {
        setFullscreenEnabled(false)
        logEvent(EVENTS.DIAGRAM.FULLSCREEN.EXITED)
    }

    function handleZoom(type) {
        const scale = svgDiagramRef.current.zoom(type)
        logEvent(EVENTS.DIAGRAM.ZOOMED, { scale, type })

        setZoomInOutStatus({
            in: scale < 2,
            out: scale > .25
        })
    }

    function saveDisabled() {
        return !yamlCode || prevDiagrams.length > 0
    }

    function handleHideEditor() {
        setShowEditor(false)
        logEvent(EVENTS.EDITOR.CLOSED)
    }

    function handleShowEditor() {
        setShowEditor(true)
        logEvent(EVENTS.EDITOR.OPENED)
    }

    function handleToggleFullscreen() {
        if (fullscreenEnabled) {
            logEvent(EVENTS.DIAGRAM.FULLSCREEN.EXITED)
        } else {
            logEvent(EVENTS.DIAGRAM.FULLSCREEN.ENTERED)
        }

        setFullscreenEnabled(!fullscreenEnabled)
    }

    function handleDescShown() {
        // TODO: we need to manage the state to prevent double logging
        // logEvent(EVENTS.DIAGRAM.DESC_SHOWN)
    }

    return (
        <div id="diagram-container">
            <FullScreen enabled={fullscreenEnabled} onExit={handleExitFS}>
                <Container id="diagram-toolbox" className='mb-3' fluid>
                    <div className="row justify-content-between">
                        <div className="col text-truncate">
                            <Button className='me-2 btn-sm' onClick={handleShowEditor} disabled={prevDiagrams.length}>
                                <i className="bi bi-code-square"></i> Editor
                            </Button>
                            <span className='me-2'>{renderDiagramName()}</span>

                            <ShowIf cond={diagramData.description}>
                                <OverlayTrigger trigger="click" placement="bottom" overlay={descPopover} rootClose={true}>
                                    <Button variant="light" className='me-2 no-focus' size="sm" onClick={handleDescShown}><i className="bi bi-card-text"></i></Button>
                                </OverlayTrigger>
                            </ShowIf>

                            <ShowIf cond={scenarios.length > 0 && prevDiagrams.length === 0}>
                                <select onChange={evt => handleSelectScenario(evt.target.value)}>
                                    {scenarios.map(scenario =>
                                        <option value={scenario.name} key={scenario.name}>{scenario.name}</option>
                                    )}
                                </select>
                            </ShowIf>
                        </div>
                        <div className='col-md-auto text-end'>
                            <ShowIf cond={prevDiagrams.length > 0}>
                                <Button size="sm" variant="light" onClick={onPrevDiagram}>Back</Button>
                            </ShowIf>

                            {svgDiagramRef.current &&
                                <DropdownButton className='ms-2' variant='light' as={ButtonGroup} size="sm" title={<i className="bi bi-zoom-in"></i>} id="zoom-dropdown">
                                    <Dropdown.Item disabled={!zoomInOutStatus.in} eventKey="1" onClick={() => handleZoom('in')}>Zoom In</Dropdown.Item>
                                    <Dropdown.Item disabled={!zoomInOutStatus.out} eventKey="2" onClick={() => handleZoom('out')}>Zoom Out</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={() => handleZoom('fit')}>Zoom to Fit</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={() => handleZoom(100)}>Zoom 100%</Dropdown.Item>
                                </DropdownButton>
                            }

                            <ShowIf cond={props.diagramType === 'flow'}>
                                <div className="ms-2 btn-group" role="group">
                                    {renderSpeedBtn()}
                                    {renderModeBtn()}
                                    {renderStepsBtn()}
                                </div>
                            </ShowIf>

                            <div className="ms-2 btn-group" role="group">
                                <OverlayTrigger
                                    rootClose={true}
                                    placement="bottom"
                                    trigger={saveDisabled() ? ['hover', 'focus'] : "click"}
                                    overlay={saveDisabled() ? saveTooltip : savePopover}
                                >
                                    <span className="d-inline-block">
                                        <Button size='sm' variant='outline-success' disabled={saveDisabled()}>Save</Button>
                                    </span>
                                </OverlayTrigger>

                                <ShowIf cond={props.onDelete}>
                                    <Button variant='danger' size="sm" onClick={props.onDelete}>Delete</Button>
                                </ShowIf>
                            </div>

                            <Button className='ms-2' variant='dark' size="sm" onClick={handleToggleFullscreen}>
                                <i className={'bi ' + (fullscreenEnabled ? 'bi-fullscreen-exit' : 'bi-arrows-fullscreen')}></i>
                            </Button>
                        </div>
                    </div>
                </Container>

                <Editor
                    show={showEditor}
                    yamlCode={yamlCode}
                    structure={structure}
                    template={template}
                    onChange={handleCodeChange}
                    onHide={handleHideEditor}
                    onSave={saveAndMaybeDownload}
                />

                <ShowIf cond={yamlCode}>
                    <SVGDiagram
                        ref={svgDiagramRef}
                        draggable={prevDiagrams.length === 0}
                        data={svgData}
                        layout={svgLayout}
                        appearance={appearance}
                        components={components}
                        onAnimationComplete={handleAnimationComplete}
                        onZoom={handleComponentZoom}
                        onLayoutChange={handleLayoutChange}
                    />
                </ShowIf>

                <ShowIf cond={!yamlCode}>
                    <p className='text-center'>Click on the Editor Button to write the yaml code!</p>
                </ShowIf>
            </FullScreen>
        </div>
    )
}
