import { Container } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import documentation from './documentation.md'
import faqs from './faqs.md'

export default function MarkdownPage({ name }) {
  function getMarkdown() {
    switch (name) {
      case 'faqs':
        return faqs
      case 'documentation':
        return documentation
      default:
        return ''
    }
  }

  return (
    <Container id="faqs">
      <div className='markdown-body'>
        <ReactMarkdown remarkPlugins={[remarkGfm]} children={getMarkdown()} />
      </div>
    </Container>
  )
}