import { Link } from 'react-router-dom'

export default function Landing() {
  return (
    <main id="landing">
      <div className="px-4 pt-5 my-5 text-center border-bottom">
        <h1 className="display-4 mb-3 fw-normal">
          <img id='display-logo' src='logo.png' alt='arch-explained-logo' />
        </h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead fw-normal mb-4">
            Finally, the Architecture is Well Explained
            <br />
            <br />
            Write Yaml code describing your architecture and its internal interactions.
            <br />
            It allows you to track your architecture changes in any version control system.
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <Link className="btn btn-outline-secondary" to="/">Coming soon</Link>
          </div>
        </div>
        <div className="overflow-hidden">
          <div className="container px-5">
            <img src="hero-pic.png" className="img-fluid hero-pic border rounded-3 shadow-lg" alt='screenshot' />
          </div>
        </div>
      </div>

      <div className="b-example-divider"></div>

      <div className="container px-4 py-5" id="hanging-icons">
        <h2 className="pb-2 border-bottom">What is ArchExplained</h2>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          <div className="col d-flex align-items-start">
            <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
              <i className="bi bi-code-slash"></i>
            </div>
            <div>
              <h2>Diagrams As Code</h2>
              <p>We love code and all you need to do is writing Yaml code that describes how your system is working.</p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
              <i className="bi bi-signpost-2"></i>
            </div>
            <div>
              <h2>Self Explanatory</h2>
              <p>With great yet simple animations, no need to have someone explaining the architecture. It is self explanatory!</p>
            </div>
          </div>
          <div className="col d-flex align-items-start">
            <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
              <i className="bi bi-palette2"></i>
            </div>
            <div>
              <h2>Deep Level of Details</h2>
              <p>You can add as much details as you would like. You can describe the integrations, internal parts of the system, tech stack, etc.</p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />


    </main>

  )
}