class Grid {
    constructor({ width, height, padding, count }) {
        this._width = width
        this._height = height
        this._padding = padding

        // we need an even number
        this._count = count % 2 ? count + 1 : count

        // FIXME: proper handling of only 2 cells
        if (this._count === 2) this._count = 4

        this._cells = {}
        this._memory = {}
    }

    _left() {
        return this._col * (this._width + this._padding * 2)
    }

    _top() {
        return this._row * (this._height + this._padding * 2)
    }

    _memoryKey(c, r) {
        return `c:${c}-r:${r}`
    }

    position(c, r) {
        this._col = c
        this._row = r

        return { x: this._left() + this._padding, y: this._top() + this._padding }
    }

    centerPosition(c, r) {
        const { x, y } = this.position(c, r)

        return { cx: x + this._width / 2, cy: y + this._height / 2 }
    }

    addToCell(key, c, r) {
        this._cells[key] = { c, r }
        this._memory[this._memoryKey(c, r)] = true
    }

    isEmptyCell(c, r) {
        return !this._memory[this._memoryKey(c, r)]
    }

    addElementToCell(key) {
        for (let r = 0; r < this._count / 2; r++) {
            for (let c = 0; c < this._count / 2; c++) {
                if (this.isEmptyCell(c, r)) {
                    this.addToCell(key, c, r)
                    return { c, r }
                }
            }
        }

        throw new Error('All cells are occupied!')
    }

    calcGridSize() {
        const colsNo = this._count / 2

        return {
            width: colsNo * (this._width + this._padding * 2),
            height: (this._count / colsNo) * (this._width + this._padding * 2)
        }
    }
}

export default Grid