import ShowIf from "./show-if"

export default function Notification({ onClose, visible, success = true, message }) {
    function iconClasses() {
        if (success) {
            return 'text-success bi bi-emoji-smile-fill'
        }

        return 'text-danger bi bi-emoji-frown-fill'
    }

    return (
        <ShowIf cond={visible}>
            <div className="notification-toast toast show container">
                <div className="row align-items-center">
                    <div className="col toast-body">
                        <i className={iconClasses() + ' me-2'}></i>
                        {message}
                    </div>

                    <ShowIf cond={onClose}>
                        <button className="col-md-auto toast-close" onClick={onClose}>
                            <i className="bi bi-x-square"></i>
                        </button>
                    </ShowIf>
                </div>
            </div>
        </ShowIf>
    )
}