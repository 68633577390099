const baseStructure = {
    'name': 'string',
    'type': 'string',
    'description?': 'string',
    'appearance?': {
        'show-labels?': 'boolean',
        'groups?': [{
            'name': 'string',
            'bg-color?': 'string',
            'txt-color?': 'string',
            'components': ['string'],
        }]
    }
}

export default baseStructure