import { useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Link, Switch } from 'react-router-dom';
import './App.css';
import { onAuthChange, PrivateRoute, PublicRoute } from './auth';
import Header from './components/header';
import Notification from './components/notification';
import PageViewAnalytics from './components/page-view-analytics';
import ShowIf from './components/show-if';
import './firebase';
import About from './pages/about';
import AuthHandler from './pages/auth-handler';
import ComponentPage from './pages/component-page';
import FlowPage from './pages/flow-page';
import List from './pages/list';
import Import from './pages/import';
import Inactive from './pages/inactive';
import Landing from './pages/landing';
import LoginPage from './pages/login';
import MarkdownPage from './pages/markdown-page';
import PasswordReset from './pages/password-reset';
import { fetchUserClaimsAndSetUser, store } from './store';

function ChildApp() {
  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)
  const notificationState = useSelector(state => state.notification)

  useEffect(() => onAuthChange(handleAuthChange)
    // eslint-disable-next-line
    , [])

  function handleAuthChange(user) {
    dispatch(fetchUserClaimsAndSetUser(user))
  }

  return (
    <Router>
      <div className='App d-flex flex-column min-vh-100'>
        <ShowIf cond={userState.loading}>
          <div className="spinner-container">
            <Spinner animation="border" />
          </div>
        </ShowIf>

        <PageViewAnalytics />

        <Header isLoggedIn={!!userState.current} />

        <Notification message={notificationState.message} visible={notificationState.visible} success={notificationState.success} />

        <ShowIf cond={!userState.loading}>
          <Switch>
            <PublicRoute path="/__/auth/handler" user={userState.current}>
              <AuthHandler />
            </PublicRoute>
            <PublicRoute path="/login" user={userState.current}>
              <LoginPage />
            </PublicRoute>
            <PublicRoute path="/password-reset" user={userState.current}>
              <PasswordReset />
            </PublicRoute>
            <PrivateRoute path="/flows/:flowId" user={userState.current}>
              <FlowPage />
            </PrivateRoute>
            <PrivateRoute path="/components/:componentId" user={userState.current}>
              <ComponentPage />
            </PrivateRoute>
            <PublicRoute path="/faqs" user={userState.current}>
              <MarkdownPage name='faqs' />
            </PublicRoute>
            <PublicRoute path="/documentation" user={userState.current}>
              <MarkdownPage name='documentation' />
            </PublicRoute>
            <PrivateRoute path="/list" user={userState.current}>
              <List />
            </PrivateRoute>
            <PrivateRoute path="/import" user={userState.current}>
              <Import />
            </PrivateRoute>
            <PrivateRoute path="/inactive" user={userState.current}>
              <Inactive />
            </PrivateRoute>
            <PublicRoute path="/about">
              <About />
            </PublicRoute>
            <PublicRoute path="/">
              <Landing />
            </PublicRoute>
          </Switch>
        </ShowIf>

        <Container className='mt-auto'>
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-2 border-top">
            <p className="col-md-4 mb-0 text-muted">© 2022 ArchExplained</p>

            <ul className="nav col-md-4 justify-content-end">
              <li className="nav-item"><Link to="/" className="nav-link px-2 text-muted">Home</Link></li>
              <li className="nav-item"><Link to="/about" className="nav-link px-2 text-muted">About</Link></li>
              <li className="nav-item"><Link to="/faqs" className="nav-link px-2 text-muted">FAQs</Link></li>
              <li className="nav-item"><Link to="/documentation" className="nav-link px-2 text-muted">Documentation</Link></li>
              <li className="nav-item"><a href="https://www.linkedin.com/company/archexplained" rel="noreferrer" target="_blank" className="nav-link px-2 text-muted">
                <i className="bi bi-linkedin"></i>
              </a></li>
            </ul>
          </footer>
        </Container>
      </div>
    </Router>
  )
}

export default function App() {
  return (
    <ReduxProvider store={store}>
      <ChildApp />
    </ReduxProvider>
  )
}
