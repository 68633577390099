import baseStructure from "./base-structure"

const structure = {
    ...baseStructure,
    scenarios: [{
        name: 'string',
        steps: [{
            'from': 'string',
            'to': 'string',
            'action': 'string',
            'description?': 'string',
            'req-data?': ['string'],
            'res-data?': ['string'],
        }]
    }]
}

export const defaultFlow = {
    name: '',
    type: '',
    appearance: {
        groups: []
    },
    scenarios: []
}

export default structure