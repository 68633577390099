import kafka from './kafka.png'
import inside from './inside.png'
import aurora from './aurora.png'
import documentdb from './documentdb.png'
import elasticache from './elasticache.png'
import loadbalancer from './loadbalancer.png'
import mongodb from './mongodb.png'
import mysql from './mysql.png'
import postgresql from './postgresql.png'
import openshift from './openshift.png'
import rds from './rds.png'
import s3 from './s3.png'
import rabbitmq from './rabbitmq.png'
import users from './users.png'
import kotlin from './kotlin.svg'
import angularjs from './angularjs.svg'
import firebase from './firebase.png'
import git from './git.svg'
import haskell from './haskell.svg'
import java from './java.svg'
import javascript from './javascript.svg'
import markdown from './markdown.svg'
import nextjs from './nextjs.svg'
import nodejs from './nodejs.svg'
import scala from './scala.svg'
import go from './go.svg'
import php from './php.svg'
import python from './python.svg'
import pandas from './pandas.svg'
import ruby from './ruby.svg'
import slack from './slack.svg'
import typescript from './typescript.svg'
import salesforce from './salesforce.svg'
import react from './react.svg'

const images = {
    kafka,
    users,
    inside,
    aurora,
    s3,
    rabbitmq,
    documentdb,
    rds,
    mysql,
    postgresql,
    mongodb,
    openshift,
    loadbalancer,
    elasticache,
    kotlin,
    react,
    scala,
    salesforce,
    typescript,
    slack,
    ruby,
    pandas,
    python,
    php,
    go,
    nodejs,
    nextjs,
    markdown,
    javascript,
    java,
    haskell,
    git,
    angularjs,
    firebase
}

export default images