import { useEffect } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ShowIf from '../components/show-if'
import { fetchComponents, fetchFlows } from '../store'

export default function List() {
  const dispatch = useDispatch()
  const flowsState = useSelector(state => state.flows)
  const componentsState = useSelector(state => state.components)

  useEffect(() => {
    dispatch(fetchFlows())
    dispatch(fetchComponents())
  }, [])

  return (
    <Container id="home">
      <ShowIf cond={flowsState.loading || componentsState.loading}>
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      </ShowIf>

      <h3>Flows</h3>
      <ShowIf cond={flowsState.flows.length === 0}>
        <p>You did not created any flows yet. Create a new flow <Link to="/flows/new">here</Link>.</p>
      </ShowIf>
      <div className="row row-cols-4">
        {flowsState.flows.map(flow =>
          <div className="col" key={flow.id}>
            <Link key={flow.id} to={"/flows/" + flow.id} className="text-decoration-none">
              <div className="card">
                <img className="card-img-top" src={flow.diagramUrl || '/missing.png'} alt={flow.id} />
                <div className="card-body">
                  <div className="card-title mb-0">{flow.name || '"Name not specified"'}</div>
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>

      <h3 className='mt-5'>Components</h3>
      <ShowIf cond={componentsState.components.length === 0}>
        <p>You did not created any components yet. Create a new component <Link to="/components/new">here</Link>.</p>
      </ShowIf>
      <div className="row row-cols-4">
        {componentsState.components.map(component =>
          <div className="col" key={component.id}>
            <Link key={component.id} to={"/components/" + component.id} className="text-decoration-none">
              <div className="card">
                <img className="card-img-top" src={component.componentUrl || '/missing.png'} alt={component.id} />
                <div className="card-body">
                  <div className="card-title mb-0">{component.name || '"Name not specified"'}</div>
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </Container>
  )
}