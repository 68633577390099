import { useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { sendPasswordResetEmail } from '../auth';

export default function PasswordReset() {
    const [msg, setMsg] = useState(null)

    function onSubmit(event) {
        event.preventDefault()

        const data = new FormData(event.target)
        const email = data.get('email')

        sendPasswordResetEmail(email)
            .then(() => setMsg({ content: 'An email was sent with the reset instructions!', type: 'success' }))
            .catch(err => setMsg({ content: err.message, type: 'danger' }))
    }

    return (
        <Container id="password-reset">
            <figure className='text-center mb-5'>
                <h1 className="display-1 mt-5 mb-4">
                    <img id='display-logo' src='logo.png' alt='arch-explained-logo' />
                </h1>
            </figure>

            {msg &&
                <Alert variant={msg.type} onClose={() => setMsg(null)} dismissible>{msg.content}</Alert>
            }

            <Form onSubmit={onSubmit}>
                <Form.Group controlId="email" className='mb-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Your email" name="email" />
                </Form.Group>

                <div className='text-center'>
                    <Button variant="primary" type="submit">Reset Password</Button>
                </div>
            </Form>
        </Container>
    )
}