import jsYaml from 'js-yaml'
import componentStructure from './components/diagram/structure/component-structure'
import flowStructure from './components/diagram/structure/flow-structure'
import { validate } from './components/diagram/validator'
import { createComponent, createFlow } from './firebase'
import { store } from './store'

export function fetchCredentials() {
    return new Promise((resolve, reject) => {
        try {
            const finalDestination = window.location.origin + '/__/auth/handler'
            const url = 'https://us-central1-archexplained-5cb1b.cloudfunctions.net/oAuthGitHubUri?final_destination=' + finalDestination
            // const url = 'http://localhost:5001/archexplained-5cb1b/us-central1/oAuthGitHubUri?final_destination=' + finalDestination

            window.oauth2Callback = (search) => {
                const accessToken = search.substring(14)

                fetch('https://api.github.com/user', {
                    headers: {
                        accept: 'application/vnd.github.v3+json',
                        Authorization: 'token ' + accessToken
                    }
                })
                    .then(res => res.json())
                    .then(user => {
                        resolve({ username: user.login, accessToken })
                    })
            }

            window.open(url, 'GitHub Auth Flow', 'width=500,height=600')
        } catch (err) {
            reject(err)
        }
    })
}

export function fetchFiles(repoName) {
    const username = store.getState().import.credentials.username
    const url = `https://api.github.com/repos/${username}/${repoName}/commits`

    return _fetch(url)
        .then(res => res.json())
        .then(commits => (commits[0].commit.tree.url + '?recursive=1'))
        .then(_fetch)
        .then(res => res.json())
        .then(json => json.tree)
        .catch(console.error)
}

export function fetchRepos() {
    return _fetch('https://api.github.com/user/repos')
        .then(res => res.json())
        .catch(console.error)
}

export function loadAndValidateGHFiles(files) {
    const promises = files.map(({ url, repoName }) => _fetch(url)
        .then(res => res.json())
        .then(json => atob(json.content))
        .then(yamlStr => ({ code: jsYaml.load(yamlStr), yamlStr }))
        .then(({ code, yamlStr }) => ({ errors: _validate(code), code, yamlStr, url, repoName }))
        .catch(console.error))

    return Promise.all(promises)
}

export function importFiles(files) {
    const promises = files.map(file => {
        if (file.type === 'flow') {
            return createFlow(file, null)
        }

        return createComponent(file, null)
    })

    return Promise.all(promises).catch(console.error)
}

function _validate(code) {
    const structure = code.type === 'flow' ? flowStructure : componentStructure
    return validate(code, structure)
}

function _fetch(url) {
    const accessToken = store.getState().import.credentials.accessToken

    return fetch(url, {
        headers: {
            accept: 'application/vnd.github.v3+json',
            Authorization: 'token ' + accessToken
        }
    })
}