import firebase from 'firebase/app'
import { app } from './firebase'

// Prevent the default page_view. We want to do that ourselves
window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments) }
gtag('set', { 'send_page_view': false })

const isProductionEnv = process.env.NODE_ENV === 'production'
const analytics = firebase.analytics(app)

analytics.setAnalyticsCollectionEnabled(true)

export const EVENTS = {
    EDITOR: {
        OPENED: 'Editor.Opend',
        CLOSED: 'Editor.Closed',
        SAVED: 'Editor.Saved'
    },
    DIAGRAM: {
        SAVED: 'Diagram.Saved',
        DELETED: 'Diagram.Deleted',
        IMPORTED: 'Diagram.Imported',
        CREATED: 'Diagram.Created',
        ANIMATION: {
            PLAYED: 'Diagram.Animation.Played',
            PAUSED: 'Diagram.Animation.Paused',
            RESETED: 'Diagram.Animation.Reseted',
            COMPLETED: 'Diagram.Animation.Completed',
            SPEED_CHANGED: 'Diagram.Animation.Speed_Changed',
            MODE_CHANGED: 'Diagram.Animation.Mode_Changed',
        },
        ZOOMED: 'Diagram.Zoomed',
        FULLSCREEN: {
            ENTERED: 'Diagram.FullScreen.Entered',
            EXITED: 'Diagram.FullScreen.Exited'
        },
        LEVEL: {
            IN: 'Diagram.Level.In',
            OUT: 'Diagram.Level.Out'
        },
        DESC_SHOWN: 'Diagram.Desc_Shown',
        SCENARIO_SELECTED: 'Diagram.Scenario_Selected'
    },
    IMPORT: {
        AUTHORIZED: 'Import.Authorized',
        FILES: {
            LOADED: 'Import.File.Loaded',
            VALIDATED: 'Import.File.Validated',
        },
        MODE_SWITCHED: 'Import.Mode_Switched'
    },
    SIGNUP: {
        SIGNEDUP: 'Signup.SignedUp',
        FAILED: 'Signup.Failed',
    },
    LOGIN: {
        LOGGEDIN: 'Login.LoggedIn',
        FAILED: 'Login.Failed',
    },
    SIGNOUT: {
        SIGNEDOUT: 'Signout.SignedOut',
        FAILED: 'Signout.Failed',
    }
}

export function logEvent(event, params) {
    analytics.logEvent((isProductionEnv ? '' : 'DEV::') + event, params)
}

export function logPageView(name) {
    analytics.logEvent('screen_view', { firebase_screen: name })
}