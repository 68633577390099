import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function AuthHandler() {
    const location = useLocation()

    useEffect(() => {
        window.opener.oauth2Callback(location.search)
        window.close()
    }, [location])

    return null
}