export default function svgToPng(svgText, width, height) {
    return new Promise((resolve, reject) => {
        try {
            // can use the domUrl function from the browser
            const domUrl = window.URL || window.webkitURL || window;
            if (!domUrl) throw new Error("(browser doesnt support this)")

            const margin = 0;

            // it needs a namespace
            if (!svgText.match(/xmlns="/mi)) {
                svgText = svgText.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
            }

            // create a canvas element to pass through
            const canvas = document.createElement("canvas");
            canvas.width = width + margin * 2;
            canvas.height = height + margin * 2;
            const ctx = canvas.getContext("2d");

            // make a blob from the svg
            const svg = new Blob([svgText], {
                type: "image/svg+xml;charset=utf-8"
            });

            // create a dom object for that image
            const url = domUrl.createObjectURL(svg);

            // create a new image to hold it the converted type
            const img = new Image();

            // when the image is loaded we can get it as base64 url
            img.onload = function () {
                // draw it to the canvas
                ctx.drawImage(this, margin, margin);

                // we don't need the original any more
                domUrl.revokeObjectURL(url);
                // now we can resolve the promise, passing the image blob
                canvas.toBlob(resolve);
            };

            // load the image
            img.src = url;

        } catch (err) {
            reject('failed to convert svg to png ' + err);
        }
    });
}