import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { logPageView } from "../analytics"

export default function PageViewAnalytics() {
    const location = useLocation()

    useEffect(() => {
        let name
        const title = 'ArchExplained'

        switch (location.pathname) {
            case '/import':
                name = 'Import'
                break

            case '/documentation':
                name = 'Documentation'
                break

            case '/flows/new':
                name = 'New Flow'
                break

            case '/components/new':
                name = 'New Component'
                break

            case '/faqs':
                name = 'FAQs'
                break

            case '/list':
                name = 'List'
                break

            case '/login':
                name = 'Login'
                break

            case '/password-reset':
                name = 'Password Reset'
                break

            case '/inactive':
                name = 'Inactive'
                break

            case '/':
            default:
                name = ''
        }

        if(!name && /\/flows\/.+/.test(location.pathname)) {
            name = 'Flow'
        }

        if(!name && /\/components\/.+/.test(location.pathname)) {
            name = 'Component'
        }

        document.title = (name ? name + ' - ' : '') + title
        logPageView(name)
    }, [location])

    return null
}