import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/analytics'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB_SwAIbfAYudX_D8NwAdq_HSH3z3gTvaA",
    authDomain: "archexplained-5cb1b.firebaseapp.com",
    projectId: "archexplained-5cb1b",
    storageBucket: "archexplained-5cb1b.appspot.com",
    messagingSenderId: "792536487549",
    appId: "1:792536487549:web:a55fe4d9b0af8e7ad64314",
    measurementId: "G-9MCQE2R2Y6"
}

// Initialize Firebase
export const app = firebase.apps.length ? firebase.app() : firebase.initializeApp(firebaseConfig)

const storageRef = firebase.storage().ref()
const db = firebase.firestore();

function diagramImageUrlFromId(id) {
    return `diagram-image-${id}`
}

function serializeDates(entity) {
    return Object.keys(entity).reduce((acc, key) => {
        let value = entity[key]

        if (key === 'updatedAt' || key === 'createdAt') {
            value = entity[key].toMillis()
        }

        acc[key] = value
        return acc
    }, {})
}

export function uploadFile(file, name) {
    const ref = storageRef.child(name)

    return ref.put(file)
        .then(() => ref.getDownloadURL())
        .catch(error => console.error("Error uploading file: ", error))
}

export function createFlow(diagram, imageBlob) {
    const now = firebase.firestore.Timestamp.now()
    const currentUser = firebase.auth().currentUser

    if (!currentUser) {
        return Promise.reject('Current User is not loaded yet')
    }

    const _diagram = {
        ...diagram,
        userId: currentUser.uid,
        createdAt: now,
        updatedAt: now,
    }

    let id

    return db.collection("diagrams").add(_diagram)
        .then(docRef => {
            if (imageBlob) {
                updateFlow(docRef.id, _diagram, imageBlob)
            }

            id = docRef.id
        })
        .then(() => console.info(`Diagram successfullly created!`))
        .then(() => id)
}

export function fetchFlows() {
    const currentUser = firebase.auth().currentUser

    if (!currentUser) {
        return Promise.reject('Current User is not loaded yet')
    }

    return db.collection('diagrams')
        .where('userId', '==', currentUser.uid)
        .get().then((querySnapshot) => {
            const diagrams = []

            querySnapshot.forEach((doc) => {
                const data = doc.data()

                diagrams.push(serializeDates({
                    id: doc.id,
                    ...data,
                }))
            });

            return diagrams;
        });
}

export function fetchFlowById(id) {
    return db.collection('diagrams').doc(id).get().then(doc => serializeDates(doc.data()))
}

export function updateFlow(id, diagram, imageBlob) {
    const now = firebase.firestore.Timestamp.now()

    return uploadFile(imageBlob, diagramImageUrlFromId(id))
        .then(diagramUrl => {
            const _diagram = {
                ...diagram,
                diagramUrl,
                updatedAt: now,
            }

            return db.collection('diagrams').doc(id)
                .set(_diagram, { merge: true })
                .then(() => console.info(`Diagram [${id}] successfullly updated!`))
                .catch(error => console.error("Error updating diagram: ", error))
        })
}

export function deleteFlow(id) {
    return db.collection('diagrams').doc(id).delete()
        .then(() => {
            console.info(`Diagram [${id}] successfullly deleted!`)

            return storageRef.child(diagramImageUrlFromId(id)).delete()
                .then(() => console.info(`Diagram [${id}] image successfullly deleted!`))
                .catch(error => console.error("Error deleting file: ", error))
        })
        .catch(error => console.error("Error deleting diagram: ", error))
}

// ------------- Components ------------- //

export function fetchComponents() {
    const currentUser = firebase.auth().currentUser

    if (!currentUser) {
        return Promise.reject('Current User is not loaded yet')
    }


    return db.collection('components')
        .where('userId', '==', currentUser.uid)
        .get()
        .then((querySnapshot) => {
            const components = []

            querySnapshot.forEach((doc) => {
                const data = doc.data()

                components.push(serializeDates({
                    id: doc.id,
                    ...data,
                }))
            })

            return components;
        })
        .catch(error => console.error("Error fetching components: ", error));
}

export function createComponent(component, imageBlob) {
    const now = firebase.firestore.Timestamp.now()
    const currentUser = firebase.auth().currentUser

    if (!currentUser) {
        return Promise.reject('Current User is not loaded yet')
    }

    const _component = {
        ...component,
        userId: currentUser.uid,
        createdAt: now,
        updatedAt: now,
    }

    let id

    return db.collection("components").add(_component)
        .then(docRef => {
            if (imageBlob) {
                updateComponent(docRef.id, _component, imageBlob)
            }

            id = docRef.id
        })
        .then(() => console.info(`Component successfullly created!`))
        .then(() => id)
}

export function fetchComponentById(id) {
    return db.collection('components').doc(id).get().then(doc => serializeDates(doc.data()))
}

export function updateComponent(id, component, imageBlob) {
    const now = firebase.firestore.Timestamp.now()

    return uploadFile(imageBlob, diagramImageUrlFromId(id))
        .then(componentUrl => {
            const _component = {
                ...component,
                componentUrl,
                updatedAt: now,
            }

            return db.collection('components').doc(id)
                .set(_component, { merge: true })
                .then(() => console.info(`Component [${id}] successfullly updated!`))
                .then(() => serializeDates(_component))
                .catch(error => console.error("Error updating component: ", error))
        })
}

export function deleteComponent(id) {
    return db.collection('components').doc(id).delete()
        .then(() => {
            console.info(`Component [${id}] successfullly deleted!`)

            return storageRef.child(diagramImageUrlFromId(id)).delete()
                .then(() => console.info(`Component [${id}] image successfullly deleted!`))
                .catch(error => console.error("Error deleting file: ", error))
        })
        .catch(error => console.error("Error deleting component: ", error))
}

// ------------- Authorization ------------- //

export function signout() {
    return firebase.auth().signOut()
}

export function serializableUser(user) {
    if (user) {
        const { uid, displayName, photoURL, email, emailVerified, phoneNumber, claims } = user
        return { uid, displayName, photoURL, email, emailVerified, phoneNumber, claims }
    }

    return null
}

export function fetchUserClaimsAndSetUser(user) {
    if (user) {
        return firebase.auth().currentUser.getIdTokenResult()
            .then(({ claims }) => {
                return {
                    ...serializableUser(user),
                    claims: {
                        active: claims.active
                    }
                }
            })
    }

    return Promise.resolve(null)
}