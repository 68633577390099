import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router"
import { useHistory } from 'react-router-dom'
import EditorDiagram from '../components/diagram/editor-diagram'
import ShowIf from '../components/show-if'
import { createFlow, deleteFlow, fetchComponents, fetchFlowById, resetFlow, showNotification, updateFlow } from '../store'

export default function FlowPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { flowId } = useParams()
  const flowsState = useSelector(state => state.flows)
  const componentsState = useSelector(state => state.components)

  useEffect(() => {
    if (flowId === 'new') {
      dispatch(fetchComponents())
      dispatch(resetFlow())
    } else {
      dispatch(fetchFlowById(flowId))
      dispatch(fetchComponents())
    }
  }, [flowId])

  function handleDelete() {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteFlow(flowId)).then(() => history.push('/list'))
    }
  }

  function handleSave(diagram, imageBlob) {
    if (flowId === 'new') {
      return dispatch(createFlow([diagram, imageBlob]))
        .unwrap()
        .then(id => {
          dispatch(showNotification({ message: 'Diagram successfully created!', success: true }))
          history.push('/flows/' + id)
        })
        .catch(() => dispatch(showNotification({ message: 'Error creating diagram!', success: false })))
    } else {
      return dispatch(updateFlow([flowId, diagram, imageBlob]))
        .then(() => dispatch(showNotification({ message: 'Diagram successfully updated!', success: true })))
        .catch(() => dispatch(showNotification({ message: 'Error updating diagram!', success: false })))
    }
  }

  return (
    <div className='editor-page'>
      <ShowIf cond={flowsState.loading || componentsState.loading}>
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      </ShowIf>

      <EditorDiagram
        yamlCode={flowsState.flow.yamlCode}
        layoutString={flowsState.flow.layout}
        components={componentsState.components}
        diagramType='flow'
        onSave={handleSave}
        onDelete={flowId === 'new' ? null : handleDelete}
      />
    </div>
  )
}