import images from './diagram/images/images';
import { Popover, ListGroup } from 'react-bootstrap';

export function isReservedWord(word) {
    return !!images[word.toLowerCase()]
}

export function validationPopover(props, errors) {
    function message({ type, value, enumArr, key }) {
        if (type === 'required') {
            return 'is missing'
        }

        if (type === 'reserved') {
            return <span>is invalid. <code>{value}</code> is a reserved word</span>
        }

        if (type === 'enum') {
            return <span>is invalid. <code>{key}</code> should be one of <code>[{enumArr.join(', ')}]</code></span>
        }

        return <span>should be in type <code>{type}</code></span>
    }

    return (
        <Popover id="validation-popover" {...props}>
            <Popover.Body>
                <ListGroup variant="flush">
                    {errors.map(error =>
                        <ListGroup.Item key={error.key}>
                            <span style={{ whiteSpace: 'nowrap' }}><code>{error.key}</code> {message(error)}</span>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Popover.Body>
        </Popover>
    )
}

