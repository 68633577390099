import { Link } from 'react-router-dom'

export default function About() {
  return (
    <main id="landing">
      <div className="px-4 pt-5 my-5 text-center">
        <h1 className="display-4 mb-3 fw-normal">
          <img id='display-logo' src='logo.png' alt='arch-explained-logo' />
        </h1>
      </div>

      <div className="px-4 pt-5 my-5 text-center">
        <h1 className="display-4 fw-bold lh-1">Who are we?</h1>
        <p>ArchExplained began in founder Muhammad Hamada's living room in 2021 and was officially launched on ..., ...</p>
      </div>

      <div className="container my-5">
        <div className="row p-5 pe-0 align-items-center rounded-3 border shadow-lg">
          <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
            <h1 className="display-4 fw-bold lh-1">Our Mission</h1>
            <p className="lead">
              Our mission is to make documenting the system architecture and tracking the changes easier than ever, speed up onboarding new people to existing systems, and make endless meetings to explain the architecture to be a thing of the past.
            </p>
          </div>
          <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
            <img className="rounded-lg-3" src="mission.png" id="mission" alt='mission' />
          </div>
        </div>
      </div>



      <br />
      <br />
      <br />
      <br />
      <br />


    </main>

  )
}