import { useEffect } from 'react'
import './full-screen.css'

export default function FullScreen({ enabled, children, onExit }) {
    const classNames = ['fullscreen']

    if (enabled) {
        classNames.push('fullscreen-enabled')
    }

    useEffect(() => {
        function handleChange() {
            // We existed the fullscreen without using the exist btn
            // Probably the user used the "Esc" to exist funtionality
            if (!document.fullscreenElement) {
                if (onExit) onExit()
            }
        }

        document.addEventListener('fullscreenchange', handleChange);
        return () => document.removeEventListener('fullscreenchange', handleChange);
    }, [onExit])

    useEffect(() => {
        if (enabled) {
            if (document.documentElement) document.documentElement.requestFullscreen()
        } else {
            if (document.fullscreenElement && document.exitFullscreen) {
                document.exitFullscreen().catch(console.error)
            }
        }
    }, [enabled])

    return (
        <div className={classNames.join(' ')}>
            {children}
        </div>
    )
}