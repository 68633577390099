import { Container } from 'react-bootstrap'

export default function Inactive() {
  return (
    <Container id="inactive">
      <div className='text-center mt-5 pt-5 lead'>
        <p><strong>A</strong>rch<strong>E</strong>xplained is still in the alpha phase and is by invitation only at the moment.</p>
        <p>Thank you for your interest and <a href="mailto:archexplained.com@gmail.com">contact us</a> if you would like to be invited.</p>
      </div>
    </Container>
  )
}