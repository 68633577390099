import checkType from 'check-type'
import { isReservedWord } from '../common'

const check = checkType.init()

export const typeEnum = ['flow', 'component']

export function validate(doc, structure) {
    const structureErrors = validateStructure(doc, structure)
    const nameErrors = validateReserved('name', doc.name)
    const enumErrors = validateEnum('type', doc.type, typeEnum)

    return structureErrors.concat(nameErrors, enumErrors)
}

export function validateEnum(key, value, enumArr) {
    if (!typeEnum.includes(value)) {
        return [{ key, type: 'enum', value, enumArr }]
    }

    return []
}

export function validateReserved(key, value) {
    if (value && isReservedWord(value)) {
        return [{ key, type: 'reserved', value }]
    }

    return []
}

export function validateStructure(doc, structure, parent) {
    let notFound = [],
        current = '',
        notValid // false or path

    parent = parent || ''

    Object.keys(structure).forEach((originKey) => {
        const optional = originKey.endsWith('?')
        const key = originKey.replace(/\?$/u, '')

        current = parent
        if (!check(structure).is('Array')) {
            current += (parent.length > 0 ?
                '.' :
                '') + key
        }

        const item = structure[originKey]
        if (item instanceof Array) {
            if (check(doc).has(key) && check(doc[key]).is('Array')) {
                doc[key].forEach((child, index) => {
                    if (item.length > 1) {
                        notValid = validateStructure([child], [item[index]], current + '[' + index + ']')
                    }
                    else {
                        notValid = validateStructure([child], item, current + '[' + index + ']')
                    }
                    notFound = notFound.concat(notValid)
                })
            }
            else if (check(doc).has(key) && !check(doc[key]).is('Array')) {
                notFound.push({ key: current, type: 'array' })
            }
            else if (!optional) {
                notFound.push({ key: current, type: 'required' })
            }
        }
        else if (typeof item === 'string') {
            if (!check(doc).has(key) && optional) {
                notValid = false
            }
            else {
                const isArray = check(structure).is('Array')
                const exists = check(doc).has(key)
                const correctType = check(doc[key]).is(item)

                if (!((isArray || exists) && correctType)) {
                    notValid = { key: current, type: exists ? item : 'required' }
                } else {
                    notValid = false
                }
            }

            // Key can be a index number when the structure is an array, but passed as a string
            notFound.push(notValid ?
                notValid :
                false)
        }
        else if (typeof item === 'object' && item !== null) {
            notValid = validateStructure(doc[key], item, current)

            if (!optional && !check(doc).has(key)) {
                notValid = { key: current, type: 'required' }
            }

            notFound = notFound.concat(notValid)
        }
    })

    return notFound.filter(function filterFalse(item) {
        return item !== false
    })
}