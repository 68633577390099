export function followPath(elem, path, movingCb, finsihedCb, speed = 'Normal') {
    let counter = 0
    let isMoving = true
    let animationFrameId
    const curveLength = path.length()
    let step = stepFromSpeed(speed)

    function stepFromSpeed(speed) {
        switch (speed) {
            case 'Normal':
                return 2 / curveLength
            case 'Fast':
                return 4 / curveLength
            case 'Slow':
                return 1 / curveLength
            default:
                return 2 / curveLength
        }
    }

    function moveStar() {
        const x = path.pointAt(counter * curveLength).x - 5
        const y = path.pointAt(counter * curveLength).y - 5

        movingCb(x, y)

        // Reached the end of the path
        if (parseInt(counter, 10) === 1) {
            isMoving = false;
            cancelAnimationFrame(animationFrameId)
            finsihedCb()
        }

        // -5 to center the circle (radius = 10)
        elem.attr("transform", "translate(" + (x) + "," + (y) + ")");

        if (isMoving) {
            counter += step;
            animationFrameId = requestAnimationFrame(moveStar);
        }
    }

    animationFrameId = requestAnimationFrame(moveStar);

    function pause() {
        isMoving = false;
        cancelAnimationFrame(animationFrameId)
    }

    function stop() {
        isMoving = false;
        cancelAnimationFrame(animationFrameId)
        // finsihedCb()
    }

    function play(speed) {
        isMoving = true
        step = stepFromSpeed(speed)
        animationFrameId = requestAnimationFrame(moveStar)
    }

    return {
        pause,
        stop,
        play
    }
}

